<template>
  <div>
    <b-modal size="xl" id="modal_payment" hide-footer v-model="modal_payment" class="w-100" title="COMPROBANTES">
      
       <b-card no-body>
        <b-tabs card  content-class="mt-3">
          <b-tab title="Nuevo" active>

            <b-form @submit.prevent="Validate">
              <b-row>

                <b-col sm="5" md="2">
                  <b-form-group>
                    <label class="control-label ">Comprobante:   </label>
                    <b-form-select @change="GetSeries" v-model="payment.voucher_type" :options="voucher_type"></b-form-select>
                    <small v-if="errors.voucher_type" class="form-text text-danger" >Seleccione un comprobante</small>
                  </b-form-group>
                </b-col>

                <b-col sm="5" md="2">
                  <b-form-group>
                    <label class="control-label">Serie: </label>
                    <b-form-select v-model="payment.id_serie" :options="series"></b-form-select>
                    <small v-if="errors.id_serie" class="form-text text-danger" >Seleccione una serie</small>
                  </b-form-group>
                </b-col>

                <b-col sm="5" md="2">
                  <b-form-group>
                    <label class="control-label">Tipo Operación: </label>
                    <b-form-select @change="ListRoomControlDetailPayment" v-model="payment.operation_type" :options="operation_type"></b-form-select>
                    <small v-if="errors.operation_type" class="form-text text-danger" >Seleccione una tipo</small>
                  </b-form-group>
                </b-col>

                <b-col sm="4" md="2">
                  <b-form-group>
                    <label class="control-label">Fecha Emision: </label>
                    <b-form-input class="text-center" readonly v-model="payment.broadcast_date" type="text"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col sm="5" md="2">
                  <b-form-group>
                    <label class="control-label">Forma de Pago: <span v-if="bnt_fees_collected" class="badge badge-primary link" @click="ModalFeedCollected">Cuotas</span></label>
                    <b-form-select @change="BntFeesCollected" v-model="payment.way_to_pay" :options="way_to_pay"></b-form-select>
                    <small v-if="errors.way_to_pay" class="form-text text-danger" >Seleccione ua forma de pago</small>
                  </b-form-group>
                </b-col>

                <b-col sm="3" md="2">
                  <b-form-group>
                    <label class="control-label">Moneda: </label>
                    <b-form-select v-model="payment.coin" :options="coins"></b-form-select>
                    <small v-if="errors.coin" class="form-text text-danger" >Seleccione una moneda</small>
                  </b-form-group>
                </b-col>

                 <b-col sm="12" md="7">
                  <b-form-group>
                    <label class="control-label">Cliente: <span class="badge badge-primary link" @click="ShowModalClient">NUEVO</span></label>
                    <v-select @input="GetAddressByClient" placeholder="Seleccione un cliente" inputId="id" class="w-100" :filterable="false" label="full_name" v-model="client" @search="search" :options="clients"></v-select>
                    <small v-if="errors.id_client" class="form-text text-danger" >Seleccione un cliente</small>
                  </b-form-group>
                </b-col>

                <b-col sm="12" md="5">
                  <b-form-group>
                    <label class="control-label">Dirección: </label>
                    <b-form-input v-model="payment.address" type="text"></b-form-input>                    
                  </b-form-group>

                </b-col>


                <b-col md="12">
                  
                    <div class="table-responsive  mt-3">
                      <table class="table table-hover table-bordered">
                        <thead>
                          <tr>
                            <th width="5%" class="text-center">#</th>
                            <th width="10%" class="text-center">Codigo</th>
                            <th width="49%" class="text-center">Nombre</th>
                            <th width="7%" class="text-center">Cantidad</th>
                            <th width="10%" class="text-center">P. Unit</th>
                            <th width="10%" class="text-center">P. Total</th>
                            <th width="5%" class="text-center">Imprimir</th>
                          </tr>
                        </thead>
                        <tbody v-for="(item, index) in room_control_detail" :key="index">
                          <tr>
                              <td class="text-center">{{ index + 1 }}</td>
                              <td>
                                <input type="text" class="form-control text-center" v-model="item.code">
                              </td>
                              <td>
                                 <input type="text" class="form-control text-left" v-model="item.name">
                              </td>
                              <td>
                                 <input @change="StateChecked(index)" type="number" class="form-control text-center" v-model="item.quantity">
                              </td>
                              <td>
                                 <input @change="StateChecked(index)" type="number" step="any" class="form-control text-right" v-model="item.unit_price">
                              </td>
                              <td>
                                 <input readonly type="number" step="any" class="form-control text-right" v-model="item.total_price">
                              </td>
                              <td class="text-center">
                                <b-form-checkbox @change="StateChecked(index)" :id="'checkbox-'+ index"  v-model="item.state" :name="'checkbox-'+ index" value="1" unchecked-value="0"></b-form-checkbox>
                              </td>
                       
                          </tr>
                              
                        </tbody>
                      </table>
                    </div>

                    <small v-if="errors.total" class="form-text text-center text-danger" >Ingrese un monto</small>
                </b-col>
          
<!-- {{room_control_detail}} -->


                <b-col class="mt-4" md="12"></b-col>


                <b-col sm="12" md="8">
                  <b-row>
                    <b-col md="12">
                      <b-form-group>
                        <b-form-input readonly v-model="payment.number_to_letters" type="text"></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <div class="table-responsive mt-1">
                          <table  class="table  table-bordered table-hover table-lg mt-lg mb-0">
                            <thead>
                              <tr>
                                <th width="25%" class="text-center">Fecha</th>
                                <th width="75%" class="text-center">Referencia</th>
                              </tr>
                            </thead>
                            <tbody v-for="(item, it) in linkages" :key="it">
                              <tr>
                                  <td class="align-middle text-center">{{ item.broadcast_date }}</td>
                                  <td class="align-middle text-center">{{ item.reference }}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                    </b-col>
                    <b-col md="6">
                      <b-form-group label="Observación:">
                        <b-form-textarea rows="1"  v-model="payment.observation" max-rows="2"></b-form-textarea>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  
                </b-col>
                <b-col sm="12" md="4">
                  
                  <b-form-group label-cols-sm="6"  label="Subtotal :" class="text-right">
                    <b-form-input type="number" step="any" class="text-right" v-model="payment.subtotal" readonly ></b-form-input>
                  </b-form-group>

                  <b-form-group label-cols-sm="6"  :label="'Igv ('+ payment.igv_percentage +'%):'" class="text-right">
                    <b-form-input type="number" step="any" class="text-right" v-model="payment.igv" readonly ></b-form-input>
                  </b-form-group>

                  <b-form-group label-cols-sm="6"  label="Total :" class="text-right">
                    <b-form-input type="number" step="any" class="text-right" v-model="payment.total" readonly ></b-form-input>
                  </b-form-group>

                </b-col>

  
                <b-col md="5"></b-col>
                <b-col md="2">
                    <b-button type="submit" variant="primary" class="btn form-control">Guardar</b-button>
                </b-col>

         
              </b-row>
            </b-form>

          </b-tab>
          <b-tab title="Listar" >



            <div class="table-responsive table-height mt-3">
              <table class="table table-hover table-bordered">
                <thead>
                  <tr>
                    <th width="3%" class="text-center">#</th>
                    <th width="10%" class="text-center">Fecha</th>
                    <th width="13%" class="text-center">Comprobante</th>
                    <th width="49%" class="text-center">Cliente</th>
                    <th width="8%" class="text-center">Total</th>
                    <th width="10%" class="text-center">Estado</th>
                    <th width="10%" class="text-center">Acciones</th>
                  </tr>
                </thead>
                <tbody v-for="(item, it) in payments" :key="item.id_payment">
                  <tr>
                    <td class="text-center">{{ it + 1 }}</td>
                    <td class="text-center"> {{ item.broadcast_date }}</td>
                    <td class="text-center"> {{ CodeInvoice(item.voucher_type)+ ' '+item.serie+'-'+item.number }}</td>
                    <td class="text-left"> {{ item.name + ' - '+item.document_number }}</td>
                    <td class="text-right"> {{ item.total }} </td>
                    <td class="text-center">
                          <b-badge v-if="item.state == 0" variant="danger">XML</b-badge>
                          <b-badge v-if="item.state == 1" variant="success">Girado</b-badge>
                          <b-badge v-if="item.state == 2" variant="info">Emitido</b-badge>
                          <b-badge v-if="item.state == 3" variant="success">Aceptado</b-badge>
                          <b-badge v-if="item.state == 4" variant="danger">Rechazado</b-badge>
                          <b-badge v-if="item.state == 5" variant="warning">Anulado</b-badge>
                    </td>
                    <td class="text-center">
                      
                      <b-dropdown bloque size="sm" text="Acciones" right>
                        <b-dropdown-item v-if="Permission('PaymentEdit') && (item.state == 2 || item.state == 4)"  @click="SendXML(item.id_payment)">Enviar a Sunat</b-dropdown-item>
                        <b-dropdown-item v-if="Permission('PaymentEdit') && item.state == 3 && (item.voucher_type == '01' || item.voucher_type == '03')"  @click="NoteCredit(item.id_payment)">Gen. Nota Cred.</b-dropdown-item>
                        <b-dropdown-item v-if="Permission('PaymentEdit') && (item.state == 1 || item.state == 2 || item.state == 0)" @click="EditPayment(item.id_payment)">Editar</b-dropdown-item>
                        <b-dropdown-item v-if="Permission('PaymentView')" @click="ViewPayment(item.id_payment)">Ver</b-dropdown-item>
                        <!-- <b-dropdown-item v-if="Permission('PaymentEdit') && (item.state == 1 || item.state == 3)"  @click="ConfirmCancelPayment(item.id_payment)">Anular</b-dropdown-item> -->
                        <b-dropdown-item v-if="Permission('PaymentDelete') && (item.state == 1 || item.state == 2 || item.state == 0)"  @click="ConfirmDeletePayment(item.id_payment)">Eliminar</b-dropdown-item>
                      </b-dropdown>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>


          </b-tab>
        </b-tabs>
       </b-card>

      
    </b-modal>



  

    <LoadingComponent :is-visible="isLoading"/>

    <b-modal size="md" id="modal_fees_collected" hide-footer v-model="modal_fees_collected" class="w-100" title="CUOTAS">
      <b-form @submit.prevent="AddFeedCollected">
        <b-row>
          <b-col md="5">
            <b-form-group label="Fecha">
              <b-form-input class="text-center" :max="fee_collected.max_date" :min="fee_collected.min_date" v-model="fee_collected.date" type="date"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="Monto">
              <b-form-input class="text-right" v-model="fee_collected.total" type="number" step="any"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label=".">
              <b-button type="submit" class="form-control" variant="info"><i class="fas fa-plus-circle"></i></b-button>
            </b-form-group>
          </b-col>
        </b-row>


             <div class="table-responsive mt-3">
              <table class="table table-hover table-bordered">
                <thead>
                  <tr>
                    <th width="5%" class="text-center">#</th>
                    <th width="40%" class="text-center">Fecha</th>
                    <th width="45%" class="text-center">Total</th>
                    <th width="10%" class="text-center"></th>
                  </tr>
                </thead>
  
                <tbody v-for="(item, it) in fees_collected" :key="it">
                  <tr>
                    <td class="text-center">{{ it + 1 }}</td>
                    <td class="text-center"> {{ item.date }}</td>
                    <td class="text-right"> {{ item.total }} </td>
                    <td class="text-center">
                       <b-button @click="DeleteFeedCollected(it)" type="button" class="form-control" variant="danger"><i class="fas fa-trash"></i></b-button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

      </b-form>
    </b-modal>
  </div>
</template>


<style >


.table-responsive{
  min-height: 15px !important;
}
.table-height{
  min-height: 200px !important;
}

</style>
 
<script>
///import vue select
import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import "vue-select/src/scss/vue-select.scss";

import LoadingComponent from './../../pages/Loading'

const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
var moment = require("moment");
import { mapState,mapMutations,mapActions } from "vuex";
import EventBus from "@/assets/js/EventBus";
import converter from "@/assets/js/NumberToLetters";
import CodeToName from "@/assets/js/CodeToName";
// import ModalClient from './ModalClient'

export default {
  name: "ModalRoomControl",
  components:{
    // ModalClient,
    vSelect,
    LoadingComponent
  },
  data() {
    return {

        isLoading: false,
        fullPage: true,

        module: 'RoomControl',
        role:3,
        modal_payment:false,
        modal_fees_collected:false,

        voucher_type:[
          {value:'01', text: 'FACTURA'},
          {value:'03', text: 'BOLETA'},
          {value:'NS', text: 'NOTA DE SERVICIO'},
        ],


        payment: {
          client:'',
          way_to_pay:'01-008',
          operation_type: '01',
          id_payment:'',
          id_room_control:'',
          id_client:'',
          id_user:'',
          id_serie:'',
          voucher_type:'01',
          serie:'',
          number:'',
          broadcast_date:moment(new Date()).local().format("YYYY-MM-DD"),
          broadcast_time:moment(new Date()).local().format("HH-mm-ss"),
          expiration_date:moment(new Date()).local().format("YYYY-MM-DD"),
          coin:'PEN',
          linkages: [],
          payment_type:'',
          payment_method:'',
          payment_deadline:'',
          observation:'',
          modified_voucher_type:'',
          modified_serie:'',
          modified_number:'',
          modified_broadcast_date:'',
          modified_reason:'',
          modified_support:'',
          sunat_message:'',
          hash_cpe:'',
          hash_cdr:'',
          taxed_operation: (0).toFixed(2),
          exonerated_operation:(0).toFixed(2),
          unaffected_operation:(0).toFixed(2),
          export_operation:(0).toFixed(2),
          discount:(0).toFixed(2),
          subtotal:(0).toFixed(2),
          igv:(0).toFixed(2),
          total:(0).toFixed(2),
          cash_payment:(0).toFixed(2),
          card_payment:(0).toFixed(2),
          web_way_payment:(0).toFixed(2),
          fees_collected:[],
          state:'1',
          number_to_letters : '',
          address:'',
          igv_percentage:0,

        },
        
        room_control: {
          id_room_control:0
        },
        fees_collected: [],
        fee_collected: {
          date:moment(new Date()).local().format("YYYY-MM-DD"),
          total: (0).toFixed(2),
          min_date:moment(new Date()).local().format("YYYY-MM-DD"),
          max_date:moment(new Date()).local().format("YYYY-MM-DD"),
        },
        payments:[],
        linkages:[],

        room_control_detail:[],

        series:[],
        clients:[],
        client:null,
        bnt_fees_collected: false,
        way_to_pay:[
            {value:'01-008',text:'Contado - Efectivo'},
            {value:'01-001',text:'Contado - Depósito en Cuenta'},
            {value:'01-002',text:'Contado - Giro'},
            {value:'01-003',text:'Contado - Transferencia de Fondos'},
            {value:'01-004',text:'Contado - Orden de Pago'},
            {value:'01-005',text:'Contado - Tarjeta de Débito'},
            {value:'03-7',text:'Credito - 7 Dias'},
            {value:'03-15',text:'Credito - 15 Dias'},
            {value:'03-30',text:'Credito - 30 Dias'},
            {value:'03-45',text:'Credito - 45 Dias'},
            {value:'03-60',text:'Credito - 60 Dias'},
            {value:'03-75',text:'Credito - 75 Dias'},
            {value:'03-90',text:'Credito - 75 Dias'},
        ],
        coins:[
          {value: 'PEN', text:'PEN'},
          {value: 'USD', text:'USD'},
          {value: 'CLP', text:'CLP'},
        ],
        operation_type: [
          {value: '01', text:'Venta Interna'},
          {value: '02', text:'Exportación'},
        ],
        errors:{
          id_client:false,
          voucher_type:false,
          way_to_pay:false,
          total:false,
          id_serie:false,
        },
        validate: false,
    };
  },
  mounted () {
      EventBus.$on('ModalPaymentShow', (id_room_control) => {
        this.id_room_control = id_room_control;
        this.modal_payment = true;

        this.GetInformationPayment();
        this.ViewRoomControl();
        this.GetSeries();
        // this.ListRoomControlDetailPayment();
        this.ListPayments();

      });
     

  },
  methods: {
    search(search, loading) {
      let me = this;
      let url = this.url_base + "search-clients/" + search;
      if (search !== "") {
        loading(true);
        axios({
          method: "GET",
          url: url,
        }).then(function (response) {
              me.clients = response.data;
              loading(false);
        })
      }
    },

    GetSeries,
    ListRoomControlDetailPayment,
    TotalPrices,
    PaymentMethod,
    ShowModalClient,
    ListPayments,
    CodeInvoice,

    ViewRoomControl,
    AddPayment,
    Validate,
    ShowModalClient,
    DataPayment,
    Print,
    StateChecked,

    BntFeesCollected,
    ModalFeedCollected,
    AddFeedCollected,
    DeleteFeedCollected,

    EditPayment,
    ViewPayment,
    ConfirmDeletePayment,
    DeletePayment,
    SendXML,
    Permission,
    NoteCredit,
    GetAddressByClient,

    ConfirmCancelPayment,
    CancelPayment,


    GetInformationPayment,
    ...mapActions('RoomControl',['mLoadListRoomControl']),
  },
  computed: {
    ...mapState(["url_base"]),
    // ...mapState('RoomControl',['room_control_rc']),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    id_user: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.id_user;
    },

  },
};

function GetInformationPayment() {
  let me = this;
  let url = me.url_base + "get-information-payment";
  axios({
    method: "GET",
    url: url,
    headers: {token: me.token, module: me.module, role: me.role }
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.payment.igv_percentage = response.data.result.igv_percentage;
        me.ListRoomControlDetailPayment();
      } 
    }).catch((error) => {
      console.log(error);
    });

}


function CodeInvoice(code) {
  return CodeToName.CodeInvoice(code);
}

function GetAddressByClient() {
  let me = this;
  if (me.client == null) {
    me.payment.address = "";
    return false;
  }
  let url = me.url_base + "client/view/" + me.client.id;
  axios({
    method: "GET",
    url: url,
    headers: {token: me.token, module: me.module, role: me.role }
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.payment.address = response.data.result.address;
      }else{
        me.payment.address = "";
      } 
    }).catch((error) => {
      console.log(error);
    });

}

function NoteCredit(id_payment) {
  this.$router.push({
    name: "PaymentNoteCredit",
    params: { id_payment: je.encrypt(id_payment) },
  });
}

function GetSeries() {
  this.payment.operation_type = "01";
  if (this.payment.voucher_type == "01" || this.payment.voucher_type == "03") {
    this.operation_type = [
          {value: '01', text:'Venta Interna'},
          {value: '02', text:'Exportación'},
    ];
  }else{
    this.operation_type = [
          {value: '01', text:'Venta Interna'},
    ];
  }

  let me = this;
  let url = this.url_base + "serie/series-by-voucher/"+this.payment.voucher_type;
  this.series = [];
  axios({
    method: "GET",
    url: url,
    headers: {
      token: this.token,
      module: this.module,
      role: this.role,
    },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.payment.id_serie = '';
        for (let index = 0; index < response.data.result.length; index++) {
          const element = response.data.result[index];
          me.series.push({value: element.id_serie, text: element.serie +'-'+element.number});
          me.payment.id_serie = element.id_serie;
        }

      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
    })

}
function ListPayments() {

  let me = this;
  let url = this.url_base + "payment/list-by-room-control/"+this.id_room_control;

  axios({
    method: "GET",
    url: url,
    headers: {
      token: this.token,
      module: this.module,
      role: this.role,
    },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.payments = response.data.result;
      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
    })
}

function ListRoomControlDetailPayment() {

  let me = this;
  let url = this.url_base + "room-control/list-detail-payment-voucher/"+this.id_room_control+"/"+this.payment.operation_type;

  axios({
    method: "GET",
    url: url,
    headers: {
      token: this.token,
      module: this.module,
      role: this.role,
    },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.room_control_detail = response.data.result;
        me.TotalPrices();
        
      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
    })
}

function StateChecked(index) {
  let me = this;
  this.room_control_detail[index].quantity = this.room_control_detail[index].quantity.length == 0 ? 0:this.room_control_detail[index].quantity;
  this.room_control_detail[index].unit_price = this.room_control_detail[index].unit_price.length == 0 ? 0:this.room_control_detail[index].unit_price;
  this.room_control_detail[index].total_price = parseFloat(this.room_control_detail[index].quantity) * parseFloat(this.room_control_detail[index].unit_price);

  this.room_control_detail[index].quantity = parseFloat(this.room_control_detail[index].quantity);
  this.room_control_detail[index].unit_price = parseFloat(this.room_control_detail[index].unit_price).toFixed(2);
  this.room_control_detail[index].total_price = parseFloat(this.room_control_detail[index].total_price).toFixed(2);
  
  setTimeout(function(){ me.TotalPrices(); }, 200);
}

function TotalPrices() {
  
  
  let detail = this.room_control_detail;
  
  this.payment.taxed_operation = 0;
  this.payment.exonerated_operation = 0;
  this.payment.unaffected_operation = 0;
  this.payment.export_operation = 0;
  this.payment.discount = 0;
  this.payment.subtotal = 0;
  this.payment.igv = 0;
  this.payment.total = 0;

  let igv_percentage = parseFloat(this.payment.igv_percentage/100);
  igv_percentage  = 1 + igv_percentage

  let taxed_operation = 0;
  let exonerated_operation = 0;
  let unaffected_operation = 0;
  let export_operation = 0;

  for (let index = 0; index < this.room_control_detail.length; index++) {

    if (this.room_control_detail[index].state == "1") {
      if (this.room_control_detail[index].igv == '10') {
        taxed_operation += parseFloat(this.room_control_detail[index].total_price);
      }
      if (this.room_control_detail[index].igv == '20') {
        exonerated_operation += parseFloat(this.room_control_detail[index].total_price);
      }
      if (this.room_control_detail[index].igv == '30') {
        unaffected_operation += parseFloat(this.room_control_detail[index].total_price);
      }
      if (this.room_control_detail[index].igv == '40') {
        export_operation += parseFloat(this.room_control_detail[index].total_price);
      }
    }
  }


    this.payment.igv = taxed_operation - (taxed_operation / igv_percentage);
    this.payment.total = (taxed_operation + exonerated_operation + unaffected_operation + export_operation);
    this.payment.subtotal = (this.payment.total  - this.payment.igv);

    this.payment.taxed_operation = (taxed_operation / igv_percentage).toFixed(2);
    this.payment.exonerated_operation = exonerated_operation.toFixed(2);
    this.payment.unaffected_operation = unaffected_operation.toFixed(2);
    this.payment.export_operation = export_operation.toFixed(2);
    this.payment.discount = this.payment.discount.toFixed(2);
    this.payment.subtotal = this.payment.subtotal.toFixed(2);
    this.payment.igv = this.payment.igv.toFixed(2);
    this.payment.total = this.payment.total.toFixed(2);

    this.payment.number_to_letters =  'SON: '+ converter.NumberToLetters(this.payment.total);
 



}

function PaymentMethod(type) {
  if (type == 'card') {
    let total = parseFloat(this.payment.total) - parseFloat(this.payment.card_payment);
    if (total >= 0) {
      this.payment.cash_payment = total.toFixed(2);
      this.payment.card_payment = parseFloat(this.payment.card_payment).toFixed(2);
      this.payment.web_way_payment = (0).toFixed(2);
    }else{
      this.payment.cash_payment = parseFloat(this.payment.total).toFixed(2);
      this.payment.card_payment = (0).toFixed(2);
      this.payment.web_way_payment = (0).toFixed(2);
    }
  }

  if (type == 'web') {
    let total = parseFloat(this.payment.total) - parseFloat(this.payment.web_way_payment);
    if (total >= 0) {
      this.payment.cash_payment = total.toFixed(2);
      this.payment.card_payment = (0).toFixed(2);
      this.payment.web_way_payment = parseFloat(this.payment.web_way_payment).toFixed(2);
    }else{
      this.payment.cash_payment = parseFloat(this.payment.total).toFixed(2);
      this.payment.card_payment = (0).toFixed(2);
      this.payment.web_way_payment = (0).toFixed(2);
    }
  }

  
}

function ViewRoomControl() {
  let me = this;
  let url = this.url_base + "room-control/view/" + this.id_room_control;
  this.isLoading = true;
  axios({
    method: "GET",
    url: url,
    headers: {
      token: this.token,
      module: this.module,
      role: this.role,
    },
  })
    .then(function (response) {
      if (response.data.status == 200) {

        me.client = {id : response.data.result.invoice_customer, full_name: response.data.result.customer_name + ' - ' + response.data.result.customer_document_number};

        me.room_control.client_name = response.data.result.client_name;
        me.room_control.client_document_number = response.data.result.client_document_number;
        me.room_control.id_client = response.data.result.id_client;
        me.room_control.id_room = response.data.result.id_room;
        me.room_control.id_type_room = response.data.result.id_type_room;
        me.room_control.id_room_control = response.data.result.id_room_control;
        me.room_control.id_user = response.data.result.id_user;
        me.room_control.price = response.data.result.price;
        me.room_control.quantity = response.data.result.quantity;
        me.room_control.start_date = response.data.result.start_date;
        me.room_control.final_date = response.data.result.final_date;
        me.room_control.date = moment(response.data.result.date).local().format("YYYY-MM-DD");
        me.room_control.hour = response.data.result.hour;
        me.room_control.room_price = response.data.result.room_price;
        

        me.payment.id_client = response.data.result.invoice_customer;
        me.payment.id_room_control = response.data.result.id_room_control;
        me.payment.way_to_pay = '01-008';
        me.payment.coin = 'PEN';
        if (response.data.result.voucher.length > 0) {
          me.payment.voucher_type = response.data.result.voucher;
          me.GetSeries();
        }
        
        me.fees_collected = [];
        me.bnt_fees_collected = false;
        me.isLoading = false;
    
      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
    })

}


//modal new cliente
function ShowModalClient() {
  EventBus.$emit('ModalClient');
}

//agregar control habitacion
function AddPayment(_this) {

  let me = _this;

  me.payment.id_user = me.id_user;
  me.payment.payment_detail = me.room_control_detail;
  me.payment.id_client = me.client.id;
  me.payment.fees_collected = me.fees_collected;

  me.isLoading = true;
  let url = me.url_base + "payment/add";
  let data = me.payment;
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: {
      "Content-Type": "application/json",
      token: me.token,
      module: me.module,
      role: me.role,
    },
  })
    .then(function (response) {
      if (response.data.status == 201) {
        me.isLoading = false;
        Swal.fire("Sistema", "El comprobante de pago fue emitido", "success");
        me.modal_payment = false;
        me.mLoadListRoomControl();
        me.DataPayment(response.data.result.id_payment);
      } else if  (response.data.status == 400) {
        me.isLoading = false;
        Swal.fire("Sistema", response.data.message, "error");
      }else{
        me.isLoading = false;
          Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    });
}


function DataPayment(id_payment) {
  let url = this.url_base + "payment-pdf/" + id_payment;
  window.open(url, '_blank');

  // let me = this;
  // let url = this.url_base + "payment/data-print/"+id_payment;
  // axios({
  //   method: "GET",
  //   url: url,
  //   headers: {
  //     "Content-Type": "application/json",
  //     token: this.token,
  //     module: this.module,
  //     role: this.role,
  //   },
  // })
  //   .then(function (response) {
  //     if (response.data.status == 200) {
  //       let data = {
  //         business : response.data.business,
  //         payment : response.data.payment,
  //         payment_detail : response.data.payment_detail,
  //       }
  //       me.Print(data);
  //     }else{
  //         Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
  //     }
  //   })
  //   .catch((error) => {
  //     Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
  //   });
}



function Print(payment) {

  // let me = this;
  let url = 'http://localhost/print/consumirapi.php';
  var data = new FormData(); 
  data.append("business",JSON.stringify(payment.business)); 
  data.append("payment",JSON.stringify(payment.payment));  
  data.append("payment_detail",JSON.stringify(payment.payment_detail));  
  axios({
    method: "POST",
    url: url,
    data:data,
    headers: {
      "Content-Type": "application/json",
      "Accept":"*/*",
    },
  })
    .then(function (response) {
     
    })
    .catch((error) => {
      console.log(error);
    });
}

function Validate() {

  this.validate = false;

  this.errors.voucher_type = this.payment.voucher_type.length == 0 ? true : false;
  this.errors.id_serie = this.payment.id_serie.length == 0 ? true : false;
  this.errors.way_to_pay = this.payment.way_to_pay.length == 0 ? true : false;
  this.errors.id_client = this.payment.id_client == null ? true : false;
  this.errors.total = parseFloat(this.payment.total) == 0 ? true : false;
  

  if (this.errors.voucher_type) { this.validate = true; return false;}else{ this.validate = false; }
  if (this.errors.id_serie) { this.validate = true; return false;}else{ this.validate = false; }
  if (this.errors.way_to_pay) { this.validate = true; return false;}else{ this.validate = false; }
  if (this.errors.id_client) { this.validate = true; return false;}else{ this.validate = false; }
  if (this.errors.total) { this.validate = true; return false;}else{ this.validate = false; }
  

  let me = this;

  Swal.fire({
    title: 'Esta seguro de guardar el comprobante ?',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Si, Estoy de Acuerdo!'
  }).then((result) => {
    if (result.isConfirmed) {
      AddPayment(me);
    }
  })

}



function SendXML(id_payment) {


  let me = this;
  let url = this.url_base + "payment/send-xml/"+ id_payment;
  me.isLoading = true;

  axios({
    method: "GET",
    url: url,
    headers: {
      token: this.token,
      module: this.module,
      role: 3,
    },
  })
    .then(function (response) {

      if (response.data.status == 200) {
          me.isLoading = false;
          if (response.data.result.state == 3) {
                for (var i = 0; i < me.payments.length; i++) {
                  if (me.payments[i].id_payment == id_payment) {
                      me.payments[i].state = 3;
                      break;
                    }
                }
                Swal.fire({ icon: 'success', text: 'El comprobante electronico a sido aceptado correctamente', timer: 3000,})
          }
          if (response.data.result.state == 4) {
                for (var i = 0; i < me.payments.length; i++) {
                  if (me.payments[i].id_payment == id_payment) {
                      me.payments[i].state = 4;
                      break;
                    }
                }
                Swal.fire({ icon: 'error', text: 'El comprobante electronico fue rechazado', timer: 3000,})
          }

      } else {
          me.isLoading = false;
          for (var i = 0; i < me.payments.length; i++) {
            if (me.payments[i].id_payment == id_payment) {
              me.payments[i].state = 6;
              break;
            }
          }

          Swal.fire({ icon: 'error', text: 'A ocurido un error', timer: 3000,})
    
      }
    })

}

// Editar usuario
function EditPayment(id_payment) {
  let routeUrl = this.$router.resolve({
    name: "PaymentEdit",
    params: { id_payment: je.encrypt(id_payment) },
  });

  window.open(routeUrl.href, '_blank');
}

// Ver Usuario
function ViewPayment(id_payment) {
  let routeUrl = this.$router.resolve({
    name: "PaymentView",
    params: { id_payment: je.encrypt(id_payment) },
  });
  window.open(routeUrl.href, '_blank');
}

// Confirmar eliminar
function ConfirmDeletePayment(id_payment) {
  Swal.fire({
    title: "Esta seguro de eliminar el registro?",
    text: "No podrás revertir esto!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, Estoy de acuerdo!",
  }).then((result) => {
    if (result.value) {
      this.DeletePayment(id_payment);
    }
  });
}

// eliminar usuario
function DeletePayment(id_payment) {
  
  let me = this;
  me.isLoading = true;
  let url = this.url_base + "payment/delete/" + id_payment;
  axios({
    method: "delete",
    url: url,
    headers: { token: this.token, module: this.module, role: 4,},
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.isLoading = false;
        for (var i = 0; i < me.payments.length; i++) {
          if (me.payments[i].id_payment == id_payment) {
            me.payments.splice(i, 1);
            me.GetSeries();
            break;
          }
        }
        Swal.fire({icon: 'success',text: 'Se ha eliminado el registro', timer: 2000,})
      }else{
        me.isLoading = false;
        Swal.fire({icon: 'error', text: 'No se puede eliminar el pago', timer: 2000,})
      }
    })
    
}


// CUOTAS DE PAGO
function BntFeesCollected() {
  let payment_type = this.payment.way_to_pay.split('-');
  let days = 0;
  if (payment_type[0] == "03") {
    this.bnt_fees_collected = true;
    days = payment_type[1];
  }else{
    this.bnt_fees_collected = false;
  }
}

function ModalFeedCollected() {
  this.modal_fees_collected = true;
  let payment_type = this.payment.way_to_pay.split('-');
  let days = payment_type[1];
  let balance = 0;
  for (let index = 0; index < this.fees_collected.length; index++) {
    const element = this.fees_collected[index];
    balance += parseFloat(element.total);
  }
  this.fee_collected.total = parseFloat(this.payment.total) - parseFloat(balance); 
  this.fee_collected.total = this.fee_collected.total.toFixed(2);

  this.fee_collected.max_date = moment(this.fee_collected.min_date, "YYYY-MM-DD").add('days',parseInt(days)).local().format("YYYY-MM-DD");
  
}

function AddFeedCollected() {
  let balance = 0;
  for (let index = 0; index < this.fees_collected.length; index++) {
    const element = this.fees_collected[index];
    balance += parseFloat(element.total);
  }
  balance = parseFloat(this.payment.total) - parseFloat(balance); 


  if (this.fee_collected.date.length == 0) {
    return false;
  }
  if (this.fee_collected.total.length == 0 || balance < parseFloat(this.fee_collected.total))  {
    return false;
  }
  
  this.fees_collected.push({date:this.fee_collected.date, total: parseFloat(this.fee_collected.total).toFixed(2)});

  balance = 0;
  for (let index = 0; index < this.fees_collected.length; index++) {
    const element = this.fees_collected[index];
    balance += parseFloat(element.total);
  }
  this.fee_collected.total = parseFloat(this.payment.total) - parseFloat(balance); 
  this.fee_collected.total = this.fee_collected.total.toFixed(2);

  
}

function DeleteFeedCollected(index) {
  for (var i = 0; i < this.fees_collected.length; i++) {
    if (i == index) {
      this.fees_collected.splice(i, 1); break;
    }
  }

  let balance = 0;
  for (let index = 0; index < this.fees_collected.length; index++) {
    const element = this.fees_collected[index];
    balance += parseFloat(element.total);
  }
  this.fee_collected.total = parseFloat(this.payment.total) - parseFloat(balance); 
  this.fee_collected.total = this.fee_collected.total.toFixed(2);
  
}


function ConfirmCancelPayment(id_payment) {
  Swal.fire({
    title: "Esta seguro de anular el comprobante?",
    text: "No podrás revertir esto!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, Estoy de acuerdo!",
  }).then((result) => {
    if (result.value) {
      this.CancelPayment(id_payment);
    }
  });
}

function CancelPayment(id_payment) {
  let me = this;
  me.isLoading = true;
  let url = this.url_base + "payment-low/add";
  let data = {
    id_payment:id_payment,
    id_user:this.id_user
  };
  axios({
    method: "post",
    url: url,
    data:data,
    headers: { token: this.token, module: this.module, role: 4,},
  })
    .then(function (response) {
      if (response.data.status == 201) {
        me.isLoading = false;
        for (var i = 0; i < me.payments.length; i++) {
          if (me.payments[i].id_payment == id_payment) {
            me.payments[i].state = response.data.result.state;
            break;
          }
        }
        Swal.fire({icon: 'success',text: 'Se ha anulado el comprobante', timer: 2000,})
      }else{
        me.isLoading = false;
        Swal.fire({icon: 'error', text: 'No se puede eliminar el comprobante', timer: 2000,})
      }
    })
}





function Permission(module_permission) {
  let user_permissions = window.localStorage.getItem("user_permissions");
  user_permissions = JSON.parse(JSON.parse(je.decrypt(user_permissions)));
  if (user_permissions.indexOf(module_permission) > -1) {
    return true;
  } else {
    return false;
  }
}
</script>