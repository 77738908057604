<template>
  <div>
    <b-modal size="xl" id="modal_payment_account" hide-footer v-model="modal_payment_account" class="w-100" title="ABONOS">
      

          
            <b-tabs content-class="mt-3">

              <b-tab title="Nuevo" active>
                  <b-form @submit.prevent="Validate">
                        <b-row>
                          
                          <b-col md="12">
                              <div class="table-responsive mt-3">
                                <table class="table table-hover table-bordered">
                                  <thead>
                                    <tr>
                                      <th width="5%" class="text-center">#</th>
                                      <th width="85%" class="text-center">Concepto</th>
                                      <th width="10%" class="text-center">P. Total</th>
                                    </tr>
                                  </thead>
                                  <tbody >
                                    <tr v-for="(item, index) in room_control_detail" :key="index">
                                        <td class="text-center">{{ index + 1 }}</td>
                                        <td class="text-left">{{ item.name + ' - '+ item.code }}</td>
                                        <td class="text-right">{{ item.total_price }}</td>
                                    </tr>
                                    <tr >
                                        <td class="text-center">{{ total_Detail + 1 }}</td>
                                        <td class="text-left">Abonos</td>
                                        <td class="text-right">{{ room_control.total_paid }}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">{{ total_Detail + 2 }}</td>
                                        <td class="text-left">Saldo</td>
                                        <td class="text-right">{{ payment_account.balance }}</td>
                                    </tr>
                                        
                                  </tbody>
                                </table>
                              </div>
                              
                          </b-col>
                    
                          <b-col class="mt-4" md="12"></b-col>

                          

                          <b-col sm="12" md="3">
                            <b-form-group>
                              <label class="control-label">Comprobante: </label>
                              <b-form-select v-model="payment_account.id_serie" :options="series"></b-form-select>
                              <small v-if="errors.id_client" class="form-text text-danger" >Seleccione un cliente</small>
                            </b-form-group>
                          </b-col>

                          <b-col sm="6" md="2">
                            <b-form-group>
                              <label class="control-label">Fecha Emision: </label>
                              <b-form-input class="text-center" readonly v-model="payment_account.broadcast_date" type="date"></b-form-input>
                            </b-form-group>
                          </b-col>

                          <b-col sm="6" md="2">
                            <b-form-group>
                              <label class="control-label">Forma de Pago: </label>
                              <b-form-select v-model="payment_account.payment_type" :options="payment_type"></b-form-select>
                            </b-form-group>
                          </b-col>

                          <b-col sm="8" md="3">
                            <b-form-group>
                              <label class="control-label">Referencia: </label>
                              <b-form-input v-model="payment_account.reference" type="text"></b-form-input>                    
                            </b-form-group>
                          </b-col>

                          <b-col sm="4" md="2">
                            <b-form-group>
                              <label class="control-label">Moneda: </label>
                              <b-form-select v-model="payment_account.coin" :options="coins"></b-form-select>
                              <small v-if="errors.coin" class="form-text text-danger" >Seleccione una moneda</small>
                            </b-form-group>
                          </b-col>

                          

                          <b-col sm="12" md="5">
                            <b-form-group>
                              <!-- <label class="control-label">Cliente: </label>
                              <input class="form-control" :value="room_control.client_name + ' - ' + room_control.client_document_number" readonly /> -->
                              <label class="control-label">Cliente: <span class="badge badge-primary link" @click="ShowModalClient">NUEVO</span></label>
                              <v-select placeholder="Seleccione un cliente" inputId="id" class="w-100" :filterable="false" label="full_name" v-model="client" @search="search" :options="clients"></v-select>
                              <small v-if="errors.id_client" class="form-text text-danger" >Seleccione un cliente</small>
                            </b-form-group>
                          </b-col>

                          <b-col sm="12" md="5">
                            <b-form-group>
                              <label class="control-label">Concepto: </label>
                              <b-form-input v-model="payment_account.observation" type="text"></b-form-input>  
                              <small v-if="errors.observation" class="form-text text-danger" >Ingrese un concepto</small>                  
                            </b-form-group>
                          </b-col>

                          <b-col sm="12" md="2">
                            <b-form-group>
                              <label class="control-label">Total: </label>
                              <b-form-input v-model="payment_account.total" class="text-right" type="number" step="any"></b-form-input> 
                              <small v-if="errors.total" class="form-text text-danger" >Ingrese un monto mayor a 0</small>                     
                            </b-form-group>
                          </b-col>


                          <b-col md="6">
                            <b-form-group>
                              <button type="button" @click="ExportAccountStatus" class="btn btn-warning form-control">Imprimir Estado de Cuenta</button>
                            </b-form-group>
                          </b-col>
                          <b-col md="6">
                            <b-form-group>
                              <button type="submit" :disabled="btn_disabled" class="btn btn-info form-control">Efectuar Pago</button>
                            </b-form-group>
                          </b-col>

                  
                        </b-row>
                      </b-form>


              </b-tab>
              <b-tab title="Listar" >

                    <b-col md="12">
                        <div class="table-responsive mt-3">
                          <table class="table table-hover table-bordered">
                            <thead>
                              <tr>
                                <th width="5%" class="text-center">#</th>
                                <th width="8%" class="text-center">Fecha</th>
                                <th width="10%" class="text-center">TC</th>
                                <!-- <th width="6%" class="text-center">Serie</th>
                                <th width="6%" class="text-center">Numero</th> -->
                                <th width="35%" class="text-center">Cliente</th>
                                <th width="5%" class="text-center">Moneda</th>
                                <th width="7%" class="text-center">Total</th>
                                <th width="12%" class="text-center">Usuario</th>
                                <th width="7%" class="text-center">Estado</th>
                                <th width="10%" class="text-center">Acciones</th>
                              </tr>
                            </thead>
                            <tbody v-for="(item, index) in list_payment_account" :key="index">
                              <tr>
                                  <td class="text-center">{{ index + 1 }}</td>
                                  <td class="text-center">{{ item.broadcast_date }}</td>
                                  <td class="text-center">{{ CodeInvoice(item.voucher_type) +' '+item.serie +'-'+ item.number}}</td>
                        
                                  <td class="text-left">{{ item.name + ' - '+ item.document_number }}</td>
                                  <td class="text-center">{{ item.coin }}</td>
                                  <td class="text-right">{{ item.total }}</td>
                                  <td class="text-right">{{ item.email }}</td>
                                  <td class="text-center">
                                      <b-badge v-if="item.state == 1" variant="success">Activo</b-badge>
                                      <b-badge v-if="item.state == 0" variant="danger">Anulado</b-badge>
                                  </td>
                                  <td class="text-center">
                                  
                                     <b-dropdown bloque size="sm" text="Acciones" right>
                                        <b-dropdown-item @click="ViewPaymentAccount(item.id_payment_account)" >Ver</b-dropdown-item>
                                        <b-dropdown-item @click="ConfirmCancelPaymentAccount(item.id_payment_account)">Anular</b-dropdown-item>
                                      </b-dropdown>
                                  </td>
                              </tr>
                                  
                            </tbody>
                          </table>
                        </div>
                    </b-col>



              </b-tab>
              
            </b-tabs>
          


      
    </b-modal>

    <LoadingComponent :is-visible="isLoading"/>

  </div>
</template>


<style >



</style>
 
<script>
///import vue select
import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import "vue-select/src/scss/vue-select.scss";

import LoadingComponent from './../../pages/Loading'

const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
var moment = require("moment");
import { mapState,mapMutations,mapActions } from "vuex";
import EventBus from "@/assets/js/EventBus";
import converter from "@/assets/js/NumberToLetters";

import CodeToName from "@/assets/js/CodeToName";

// import ModalClient from './ModalClient'

export default {
  name: "ModalRoomControl",
  components:{
    vSelect,
    LoadingComponent
  },
  data() {
    return {
        isLoading: false,
        fullPage: true,
        btn_disabled: false,

        total_Detail : 0,
        module: 'RoomControl',
        role:3,
        modal_payment_account:false,
        list_payment_account:[],
        id_room_control:0,

        payment_account: {
          client:'',

          id_payment:'',
          id_room_control:'',
          id_client:'',
          id_user:'',
          id_serie:'',
          voucher_type:'',
          serie:'',
          number:'',
          broadcast_date:moment(new Date()).local().format("YYYY-MM-DD"),
          broadcast_time:moment(new Date()).local().format("HH-mm-ss"),
          expiration_date:moment(new Date()).local().format("YYYY-MM-DD"),
          coin:'PEN',
          payment_type:'01-001',
          reference:'',
          observation:'',
          total:(0).toFixed(2),
          cash_payment:(0).toFixed(2),
          card_payment:(0).toFixed(2),
          state:'1',
          number_to_letters : '',

        },
        
        room_control: {
          id_room_control:0
        },

        room_control_detail:[],

        series:[],
        clients:[],
        client:null,
        payment_type:[
            {value:'01-001',text:'Contado - Efectivo'},
            {value:'01-002',text:'Contado - Visa'},
            {value:'01-003',text:'Contado - Mastercard'},
            {value:'01-004',text:'Contado - American Express'},
            {value:'01-005',text:'Contado - Transferencia de Fondos'},
            {value:'03-000',text:'Crédito'},
        ],
        coins:[
          {value: 'PEN', text:'PEN'},
          {value: 'USD', text:'USD'},
          {value: 'CLP', text:'CLP'},
        ],

        errors:{
          id_client:false,
          id_serie:false,
          observation:false,
          total:false,
        },
        validate: false,
    };
  },
  mounted () {
      EventBus.$on('ModalPaymentAccountShow', (id_room_control) => {
        this.isLoading = true;
        this.id_room_control = id_room_control;
        this.ListRoomControlDetailPayment();
        this.ViewRoomControl();
        this.modal_payment_account = true;
        this.GetSeries();
        this.ListPaymentAccount();
        
        
        
      });

      

  },
  methods: {
      search(search, loading) {
        let me = this;
        let url = this.url_base + "search-clients/" + search;
        if (search !== "") {
          loading(true);
          axios({
            method: "GET",
            url: url,
          }).then(function (response) {
                me.clients = response.data;
                loading(false);
          })
        }
      },


      ListPaymentAccount,
      ResetPaymentAccount,
      CodeInvoice,
      ViewPaymentAccount,
      ConfirmCancelPaymentAccount,
      CancelPaymentAccount,

      GetSeries,
      ListRoomControlDetailPayment,
      TotalPrices,
      ShowModalClient,

      ViewRoomControl,
      AddPayment,
      Validate,
      ShowModalClient,
      DataPayment,
      Print,
      ExportAccountStatus,
      ...mapActions('RoomControl',['mLoadListRoomControl']),
  },
  computed: {
    ...mapState(["url_base"]),
    // ...mapState('RoomControl',['room_control_rc']),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    id_user: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.id_user;
    },

  },
};

function ResetPaymentAccount() {

  this.payment_account.coin = 'PEN';
  this.payment_account.payment_type = '01-001';
  this.payment_account.reference = '';
  this.payment_account.observation = '';
  this.ViewRoomControl();
  this.GetSeries();
  this.ListRoomControlDetailPayment();
  this.ListPaymentAccount();
  // this.TotalPrices();
  
}
function CodeInvoice(code) {
  return CodeToName.CodeInvoice(code);
}
function ListPaymentAccount() {
  

  let me = this;
  let url = this.url_base + "payment-account/list-all/"+this.id_room_control;

  axios({
    method: "GET",
    url: url,
    headers: {
      token: this.token,
      module: 'PaymentAccount',
      role: 1,
    },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.list_payment_account = response.data.result;
        
        setTimeout(function(){ me.TotalPrices(); me.isLoading = false; }, 500);

        
      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
    })
}




function GetSeries() {
  let me = this;
  let url = this.url_base + "series-payment-account";
  this.series = [];
  axios({
    method: "GET",
    url: url,
    headers: {
      token: this.token,
      module: this.module,
      role: this.role,
    },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        let selected = true
        for (let index = 0; index < response.data.result.length; index++) {
          const element = response.data.result[index];
          me.series.push({value: element.id_serie, text: element.name + ' ' + element.serie +'-'+element.number});
          if (selected && element.code == 'NP') {
            me.payment_account.id_serie = element.id_serie;
            selected = false;
          }
          
        }

      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
    })

}


function ListRoomControlDetailPayment() {

  let me = this;
  let url = this.url_base + "room-control/list-detail-payment/"+this.id_room_control;

  axios({
    method: "GET",
    url: url,
    headers: {
      token: this.token,
      module: this.module,
      role: this.role,
    },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.room_control_detail = response.data.result;
        me.total_Detail = me.room_control_detail.length;
  
      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
    })
}


function ViewPaymentAccount(id_payment_account) {
      
  let route = this.$router.resolve({
    name: "PaymentAccountView",
    params: { id_payment_account: je.encrypt(id_payment_account) },
  });
  window.open(route.href, '_blank');
}

function ConfirmCancelPaymentAccount(id_payment_account) {
      
  Swal.fire({
    title: 'Esta seguro de anular el pago?',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Si, Estoy de Acuerdo!'
  }).then((result) => {
    if (result.isConfirmed) {
      this.CancelPaymentAccount(id_payment_account);
    }
  })

}

function CancelPaymentAccount(id_payment_account) {
  
  let me = this;
  me.isLoading = true;
  let url = this.url_base + "payment-account/cancel/" + id_payment_account;
  axios({
    method: "delete",
    url: url,
    headers: {
      token: this.token,
      module: 'PaymentAccount',
      role: 4,
    },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.isLoading = false;
        me.ResetPaymentAccount();
        Swal.fire({ icon: 'success', text: 'Se ha eliminado el registro', timer: 3000,})
      } else {
        me.isLoading = false;
        Swal.fire({
          icon: 'error',
          text: 'A ocurrido un error',
          timer: 2000,
        })
      }
      
    })

}

function TotalPrices() {

  this.payment_account.balance = 0;
  this.payment_account.total = 0;

  let total = parseFloat(0);
  let detail = this.room_control_detail;

  for (let index = 0; index <  detail.length; index++) {
    const element =  detail[index];
    total += parseFloat(element.total_price);
  }
  
 
  this.payment_account.balance = parseFloat(total) - parseFloat(this.room_control.total_paid);
  this.payment_account.total = (this.payment_account.balance).toFixed(2);
  this.payment_account.balance = (this.payment_account.balance).toFixed(2);
  
}


function ViewRoomControl() {
  let me = this;
  let url = this.url_base + "room-control/view/" + this.id_room_control;

  axios({
    method: "GET",
    url: url,
    headers: {
      token: this.token,
      module: this.module,
      role: this.role,
    },
  })
    .then(function (response) {
      if (response.data.status == 200) {

        me.client = {id : response.data.result.id_client, full_name: response.data.result.client_name + ' - ' + response.data.result.client_document_number};

        me.room_control.client_name = response.data.result.client_name;
        me.room_control.client_document_number = response.data.result.client_document_number;
        me.room_control.id_client = response.data.result.id_client;
        me.room_control.id_room = response.data.result.id_room;
        me.room_control.id_type_room = response.data.result.id_type_room;
        me.room_control.id_room_control = response.data.result.id_room_control;
        me.room_control.id_user = response.data.result.id_user;
        me.room_control.price = response.data.result.price;
        me.room_control.quantity = response.data.result.quantity;
        me.room_control.start_date = response.data.result.start_date;
        me.room_control.final_date = response.data.result.final_date;
        me.room_control.date = moment(response.data.result.date).local().format("YYYY-MM-DD");
        me.room_control.hour = response.data.result.hour;
        me.room_control.room_price = response.data.result.room_price;
        me.room_control.total_paid = response.data.result.total_paid;

        me.payment_account.id_client = response.data.result.id_client;
        me.payment_account.id_room_control = response.data.result.id_room_control;
    
      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
    })

}


//modal new cliente
function ShowModalClient() {
  EventBus.$emit('ModalClient');
}

//agregar control habitacion
function AddPayment(_this) {
  let me = _this;

  me.isLoading = true;

  me.payment_account.id_user = me.id_user;
  me.payment_account.id_client = me.client.id;
  me.payment_account.number_to_letters =  'SON: '+ converter.NumberToLetters(me.payment_account.total);

  let url = me.url_base + "payment-account/add";
  let data = me.payment_account;
  
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: {
      "Content-Type": "application/json",
      token: me.token,
      module: me.module,
      role: me.role,
    },
  })
    .then(function (response) {
      if (response.data.status == 201) {
        me.isLoading = false;
        Swal.fire("Sistema", "Se ha realizado el pago a cuenta ", "success");
        me.ResetPaymentAccount();
        me.mLoadListRoomControl();
        me.payment_account.observation = '';
        
        me.DataPayment(response.data.result.id_payment_account);
      } else if  (response.data.status == 400) {
        me.isLoading = false;
        Swal.fire("Sistema", response.data.message, "error");
      }else{
        me.isLoading = false;
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
    })
    .catch((error) => {
      me.isLoading = false;
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    });
}


function DataPayment(id_payment_account) {

 

  let me = this;
  let url = this.url_base + "payment-account/data-print/"+id_payment_account;
  axios({
    method: "GET",
    url: url,
    headers: {
      "Content-Type": "application/json",
      token: this.token,
      module: this.module,
      role: this.role,
    },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        let data = {
          business : response.data.business,
          payment : response.data.payment,
          payment_detail : response.data.payment_detail,
        }
        me.Print(data);
      }else{
          Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    });
}



function Print(payment_account) {

  // let me = this;
  let url = 'http://localhost/print/consumirapi.php';
  var data = new FormData(); 
  data.append("business",JSON.stringify(payment_account.business)); 
  data.append("payment",JSON.stringify(payment_account.payment)); 
  data.append("payment_detail",JSON.stringify(payment_account.payment_detail));  


  axios({
    method: "POST",
    url: url,
    data:data,
    headers: {
      "Content-Type": "application/json",
      "Accept":"*/*",
    },
  })
    .then(function (response) {
     
    })
    .catch((error) => {
      console.log(error);
      // Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    });
}

function Validate() {

  this.validate = false;

  this.errors.id_serie = this.payment_account.id_serie.length == 0 ? true : false;
  this.errors.id_client = this.client == null || this.client == {} ? true : false;
  this.errors.observation = this.payment_account.observation.length == 0 ? true : false;
  this.errors.total = this.payment_account.total.length == 0 || parseFloat(this.payment_account.total) <= 0 ? true : false;
  

  if (this.errors.id_serie) { this.validate = true; return false;}else{ this.validate = false; }
  if (this.errors.id_client) { this.validate = true; return false;}else{ this.validate = false; }
  if (this.errors.observation) { this.validate = true; return false;}else{ this.validate = false; }
  if (this.errors.total) { this.validate = true; return false;}else{ this.validate = false; }

  let me = this;

  Swal.fire({
    title: 'Esta seguro de guardar el abono?',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Si, Estoy de Acuerdo!'
  }).then((result) => {
    if (result.isConfirmed) {
      AddPayment(me);
    }
  })


}

function ExportAccountStatus() {
  

  let me = this;
  let url = this.url_base + "export-account-status/"+this.id_room_control;
  
  window.open(url,'_blank');
}


</script>