<template>
  <div>
    <b-modal size="md" id="modal_room" hide-footer v-model="modal_room" class="w-100" title="SERVICIOS DE LIMPIEZA">

        <b-form @submit.prevent="Validate">
          <div class="table-responsive mt-3">
              <table class="table table-hover table-bordered">
                <thead>
                  <tr>
                    <th width="90%" class="text-center">Servicio</th>
                    <th width="10%" class="text-center">Estado</th>
                    <th width="10%" class="text-center"></th>
                  </tr>
                </thead>
                <tbody v-for="(item, it) in house_keeping" :key="it">
                  <tr>
                    <td class="text-left"> {{ item.house_keeping }}</td>
                    <td class="text-left">
                      <b-badge v-if="item.state == 0" variant="info"></b-badge>
                      <b-badge v-if="item.state == 1" variant="warning">Pendiente</b-badge>
                      <b-badge v-if="item.state == 2" variant="primary">En Proceso</b-badge>
                      <b-badge v-if="item.state == 3" variant="success">Finalizado</b-badge>

                      
                    </td>
                    <td class="text-center">
                      <b-form-checkbox :disabled="item.state == 2 || item.state == 3" :id="'checkbox-'+ it"  v-model="item.state_cb" :name="'checkbox-'+ it" value="1" unchecked-value="0"></b-form-checkbox>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <b-button type="button" @click="SaveHouseKeeping" class="form-control" variant="primary">GUARDAR</b-button>
          
        </b-form>
    </b-modal>



    <LoadingComponent :is-visible="isLoading"/>
  </div>
</template>


<style >



</style>
 
<script>

import LoadingComponent from './../../pages/Loading'

const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
var moment = require("moment");
import { mapState,mapMutations,mapActions } from "vuex";
import EventBus from "@/assets/js/EventBus";
import ApiQuery from "@/assets/js/APIQuery";
import CodeToName from "@/assets/js/CodeToName";
export default {
  name: "ModalRoomControl",
  components:{
    LoadingComponent
  },
  data() {
    return {
        isLoading: false,
        module: 'RoomControl',
        role:3,
        modal_room:false,
        house_keeping: [],
        id_room_control: 0,
        validate: false,
    };
  },
  mounted () {
    
      EventBus.$on('ModalHousekeepingShow', (id_room_control) => {
        this.isLoading = true;
        this.id_room_control = id_room_control;
        this.modal_room = true;
        this.ListHousekeeping();
        
      });




  },
  methods: {
      
      ListHousekeeping,
      SaveHouseKeeping,
      // AddHousekeeping,
      ...mapActions('RoomControl',['mLoadListRoomControl']),
  },
  computed: {
    ...mapState(["url_base"]),
    // ...mapState('RoomControl',['room_control_rc']),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    id_user: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.id_user;
    },

  },
};



function ListHousekeeping() {
  let me = this;
  let url = this.url_base + "list-house-keeping-by-room-control/"+ this.id_room_control;

  axios({
    method: "GET",
    url: url,
    headers: {
      token: this.token,
      module: this.module,
      role: this.role,
    },
  })
    .then(function (response) {
      if (response.data.status == 200) {

        me.house_keeping = response.data.result;
   
        me.isLoading = false;
      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
    })

}

function SaveHouseKeeping() {

  
  let me = this;
  let url = this.url_base + "maintenance/save";
  me.isLoading = true;
  let data = {
    house_keeping : me.house_keeping,
    id_room_control : me.id_room_control,
  }
  me.isLoading = true;

  axios({
    method: "PUT",
    url: url,
    data: data,
    headers: {
      token: this.token,
      module: this.module,
      role: 3,
    },
  })
    .then(function (response) {
      me.ListHousekeeping();
      me.isLoading = false;
    })

}

</script>