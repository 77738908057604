<template>
  <div>
    <b-modal size="xl" id="modal_room" hide-footer v-model="modal_room" class="w-100" title="DATOS DE ESTADIA">
      <b-row>

        <b-card no-body class="col-md-12">
          <b-tabs card fill content-class="mt-3" align="center" class="w-100">
            <b-tab title="CHECK INN" active>

              <b-form @submit.prevent="Validate">
              <b-row>
          
                <b-col sm="12" md="6">
                  <b-form-group>
                    <label class="control-label">Cliente: <span class="badge badge-primary link" @click="ShowModalClient">NUEVO</span></label>
                    <v-select placeholder="Seleccione un cliente" inputId="id" class="w-100" :filterable="false" label="full_name" v-model="client" @search="search" :options="clients"></v-select>
                    <small v-if="errors.id_client" class="form-text text-danger" >Seleccione un cliente</small>
                  </b-form-group>
                </b-col>

                <b-col sm="4" md="2"> 
                  <b-form-group>
                    <label class="control-label">Tipo Habitación:</label>
                    <input type="text" readonly v-model="room.type_room_name" class="form-control text-left">
                  </b-form-group>
                </b-col>

                <b-col sm="4" md="2"> 
                  <b-form-group>
                    <label class="control-label">Habitación:</label>
                    <input type="text" readonly v-model="room.room_number" class="form-control text-left">
                  </b-form-group>
                </b-col>


                <b-col sm="4" md="2"> 
                  <b-form-group>
                    <label class="control-label">Tarifa :</label>
                    <input type="number" step="any" @change="CalculateEndDate"  v-model="room_control.price" class="form-control text-right">
                  </b-form-group>
                </b-col>
                

                <b-col sm="4" md="2">
                  <b-form-group>
                    <label class="control-label">Fecha Inicio:</label>
                    <input type="text" readonly v-model="room_control.start_date" class="form-control text-center">
                    <small v-if="errors.start_date" class="form-text text-danger" >Seleccione un fecha</small>
                  </b-form-group>
                </b-col>

                <b-col sm="4" md="2">
                  <b-form-group>
                    <label class="control-label">Hora Inicio:</label>
                    <input type="text" readonly step="1" v-model="room_control.start_hour" class="form-control text-center">
                  </b-form-group>
                </b-col>

                <b-col sm="4" md="2">
                  <b-form-group>
                    <label class="control-label">Noches:</label>
                    <input type="number" @change="CalculateEndDate" v-model="room_control.quantity" class="form-control text-center">
                  </b-form-group>
                </b-col>

                <b-col sm="4" md="2">
                  <b-form-group>
                    <label class="control-label">Fecha de Salida:</label>
                    <input type="text" v-model="room_control.final_date" readonly class="form-control text-center">
                    <small v-if="errors.final_date" class="form-text text-danger" >Seleccione un fecha</small>
                  </b-form-group>
                </b-col>

                <b-col sm="4" md="2">
                  <b-form-group>
                    <label class="control-label">Early Check In:</label>
                    <input type="number" step="any" @change="CalculateEndDate" v-model="room_control.check_inn_price" class="form-control text-right">
                  </b-form-group>
                </b-col>

                <b-col sm="4" md="2">
                  <b-form-group>
                    <label class="control-label">Late Check Out:</label>
                    <input type="number" step="any" @change="CalculateEndDate" v-model="room_control.check_out_price" class="form-control text-right">
                  </b-form-group>
                </b-col>

                

                <b-col sm="4" md="2">
                  <b-form-group>
                    <label class="control-label">Total:</label>
                    <input type="number" v-model="room_control.room_price" readonly step="any" class="form-control text-right">
                  </b-form-group>
                </b-col>

                  <b-col sm="4" md="2">
                  <b-form-group>
                    <label class="control-label">Nro de Personas:</label>
                    <input type="number" v-model="room_control.number_of_guests" class="form-control text-center">
                  </b-form-group>
                </b-col>

                <b-col sm="4" md="2"> 
                  <b-form-group>
                    <label class="control-label">Desayunos :</label>
                    <input type="number" v-model="room_control.breakfasts" class="form-control text-center">
                    <small v-if="errors.breakfasts" class="form-text text-danger" >Ingrese el nro de desayunos</small>
                  </b-form-group>
                </b-col>

                <b-col sm="4" md="2">
                  <b-form-group label="Medio de Reserva:">
                    <b-form-select ref="reserve_medium" v-model="room_control.reserve_medium" :options="reserve_medium"></b-form-select>
                    <small v-if="errors.reserve_medium" class="form-text text-danger" >Seleccione un medio de reserva</small>
                  </b-form-group>
                </b-col>

                <b-col sm="4" md="2">
                  <b-form-group label="Transporte:">
                    <b-form-select ref="transport" v-model="room_control.transport" :options="transport"></b-form-select>
                    <small v-if="errors.transport" class="form-text text-danger" >Seleccione un medio de transporte</small>
                  </b-form-group>
                </b-col>

                <b-col sm="4" md="2">
                  <b-form-group label="Motivo de Estadia:">
                    <b-form-select ref="reason_for_stay" v-model="room_control.reason_for_stay" :options="reason_for_stay"></b-form-select>
                    <small v-if="errors.reason_for_stay" class="form-text text-danger" >Seleccione un motivo</small>
                  </b-form-group>
                </b-col>

                

                <b-col sm="4" md="2">
                  <b-form-group>
                    <label class="control-label">Proximo Destino:</label>
                    <input type="text" v-model="room_control.goin_to"  class="form-control text-left">
                  </b-form-group>
                </b-col>

                <b-col sm="4" md="2">
                  <b-form-group label="Forma de Pago:">
                    <b-form-select ref="way_to_pay" v-model="room_control.way_to_pay" :options="way_to_pay"></b-form-select>
                    <small v-if="errors.way_to_pay" class="form-text text-danger" >Seleccione un forma de pago</small>
                  </b-form-group>
                </b-col>

                <b-col sm="4" md="2">
                  <b-form-group label="Tipo Comprobante:">
                    <b-form-select ref="voucher" v-model="room_control.voucher" :options="voucher"></b-form-select>
                    <small v-if="errors.voucher" class="form-text text-danger" >Seleccione un tipo de comprobante</small>
                  </b-form-group>
                </b-col>

                <b-col sm="12" md="6">
                  <b-form-group>
                    <label class="control-label">Comprobante a nombre de : <span class="badge badge-primary link" @click="ShowModalClient">NUEVO</span></label>
                    <v-select placeholder="Seleccione un cliente" inputId="invoice_customer" class="w-100" :filterable="false" label="full_name" v-model="invoice_customer" @search="search_invoice_customer" :options="invoices_customers"></v-select>
                    <small v-if="errors.invoice_customer" class="form-text text-danger" >Seleccione un cliente</small>
                  </b-form-group>
                </b-col>

                <b-col sm="12" md="12">
                  <b-form-group>
                    <label class="control-label">Observación:</label>
                    <textarea  rows="2" v-model="room_control.observation" class="form-control"></textarea>
                  </b-form-group>
                </b-col>

                <b-col md="6">
                  <b-form-group>
                    <button type="button" @click="ModalChangeRoom" class="btn btn-warning form-control">CAMBIAR HABITACION</button>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group>
                    <button type="submit" class="btn btn-info form-control">MODIFICAR</button>
                  </b-form-group>
                </b-col>

          
              </b-row>
              </b-form>

            </b-tab>
            <b-tab title="PASAJEROS">

                <b-form @submit.prevent="AddPassenger">
                <b-row>
                    <b-col  md="2"> 
                      <b-form-group>
                        <label class="control-label">Tipo Documento:</label>
                        <b-form-select ref="transport" v-model="passenger.document_type" :options="passegner_document_type"></b-form-select>
                      </b-form-group>
                    </b-col>

                    <b-col  md="3"> 
                      <b-form-group label="Nro Documento:">
                        <b-input-group>
                          <b-form-input v-model="passenger.document_number" class="form-control" ></b-form-input>
                          <b-input-group-append>
                            <b-button variant="info" @click="SearchPassenger"><b-icon icon="search"></b-icon></b-button>
                          </b-input-group-append>
                        </b-input-group>
                        <small v-if="errors.document_number" class="form-text text-danger" >{{error_document_number}}</small>
                      </b-form-group>
                    </b-col>

                    <b-col  md="5"> 
                      <b-form-group>
                        <label class="control-label">Nombres :</label>
                        <b-input v-model="passenger.name" ></b-input>
                        <small v-if="errors.name_passenger" class="form-text text-danger" >Ingrese un nombre</small>
                      </b-form-group>
                    </b-col>
                    
        

                    <b-col  md="2">
                      <b-form-group>
                        <label class="control-label"> .</label>
                        <button type="submit" class="btn btn-info form-control">REGISTRAR</button>
                      </b-form-group>
                    </b-col>


                    <div class="table-responsive w-100 pt-3">
                      <table class="table  table-hover table-bordered table-lg mt-lg mb-0">
                        <thead>
                          <tr>
                            <th  width="5%" class="text-center">#</th>
                            <th  width="10%" class="text-center">Tipo Documento</th>
                            <th  width="15%" class="text-center">Nro Documento</th>
                            <th  width="65%" class="text-center">Nombres</th>
                            <th  width="5%" class="text-center">Acciones</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(item, it) in passengers" :key="it">
                            <td class="text-center">{{ it + 1 }}</td>
                            <td class="text-left">{{ CodeDocumentType(item.document_type) }}</td>
                            <td class="text-left">{{ item.document_number }}</td>
                            <td class="text-left">{{ item.name }}</td>
                            <td class="text-center">
                              <a @click="DeletePassenger(item.id_passenger)"><i class="fas fa-trash text-danger"></i></a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

              
                  </b-row>
                  </b-form>

            </b-tab>

          </b-tabs>
        </b-card>
    


      </b-row>
    </b-modal>


    <LoadingComponent :is-visible="isLoading"/>

    <b-modal size="sm" id="modal_room" hide-footer v-model="modal_change_room" class="w-100" title="CAMBIO DE HABITACIÓN">
      <b-form @submit.prevent="ConfirmChangeRoom">
        <b-row>
          <b-col sm="12" md="6"> 
            <b-form-group>
              <label class="control-label">Habitación:</label>
              <input type="text" readonly v-model="room.room_number" class="form-control text-left">
            </b-form-group>
          </b-col>
          <b-col sm="12" md="6"> 
            <b-form-group>
              <label class="control-label">Nueva Habitación:</label>
              <b-form-select v-model="change_room.id_room" :options="free_room"></b-form-select>
            </b-form-group>
          </b-col>
          <b-col md="12">
                  <b-form-group>
                    <button type="submit" class="btn btn-info form-control">CAMBIAR HABITACIÓN</button>
                  </b-form-group>
                </b-col>
        </b-row>
      </b-form>
    </b-modal>
  </div>
</template>


<style >



</style>
 
<script>
///import vue select
import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import "vue-select/src/scss/vue-select.scss";

import LoadingComponent from './../../pages/Loading'

const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
var moment = require("moment");
import { mapState,mapMutations,mapActions } from "vuex";
import EventBus from "@/assets/js/EventBus";
import ApiQuery from "@/assets/js/APIQuery";
import CodeToName from "@/assets/js/CodeToName";
export default {
  name: "ModalRoomControl",
  components:{
    // ModalClientRoom,
    vSelect,
    LoadingComponent
  },
  data() {
    return {
        isLoading: false,
        module: 'RoomControl',
        role:3,

        modal_room:false,
        modal_change_room:false,
        id_room: 0,
        room: {
          id_room:0,
          id_type_room:0,
          room_number:'',
          photo:'',
          price:0,
          description:'',
          type_room_name:'',
        },
        room_control:{
          client_name:'',
          client_document_number:'',
          id_room:0,
          id_type_room:0,
          id_user:0,
          id_client:'',
          date:'',
          hour:'',
          start_date:'',
          final_date:'',
          price:'',
          breakfasts:0,
          check_inn_price:0,
          check_out_price:0,
          quantity:'',
          room_price:'',
          reserve_medium:'',
          company:'',
          voucher:'',
          transport:'',
          reason_for_stay:'',
          number_of_guests:'1',
          goin_to:'',
          way_to_pay:'',
          goin_to:'',

          observation:'',
          passengers:[],

        },
        free_room:[],
        change_room:{
          id_room_control:0,
          id_room:'',
        },
        reserve_medium:[
              {value:'',text:'-- Seleccione ..'},
              {value:'Booking',text:'Booking'},
              {value:'Facebook',text:'Facebook'},
              {value:'Instagram',text:'Instagram'},
              {value:'WhatsApp',text:'WhatsApp'},
              {value:'Llamada',text:'Llamada'},
              {value:'Personal',text:'Personal'},
        ],
        voucher:[
              {value:'',text:'-- Seleccione ..'},
              {value:'01',text:'Factura'},
              {value:'03',text:'Boleta'},
              {value:'NS',text:'Nota de Servicio'},
        ],
        way_to_pay:[
             {value:'',text:'-- Seleccione ..'},
              {value:'Efectivo',text:'Efectivo'},
              {value:'Visa',text:'Visa'},
              {value:'Mastercard',text:'Mastercard'},
              {value:'American Express',text:'American Express'},
              {value:'Transferencia de Fondos',text:'Transferencia de Fondos'},
        ],
        reason_for_stay:[
              {value:'',text:'-- Seleccione ..'},
              {value:'Vacaciones',text:'Vacaciones'},
              {value:'Visita a Familiares',text:'Visita a Familiares'},
              {value:'Educación',text:'Educación'},
              {value:'Atención Médica',text:'Atención Médica'},
              {value:'Religión',text:'Religión'},
              {value:'Compras',text:'Compras'},
              {value:'Negocios',text:'Negocios'},
              {value:'Trabajo',text:'Trabajo'},
              {value:'Otros',text:'Otros'},
        ],
        transport:[
              {value:'',text:'-- Seleccione ..'},
              {value:'Avión',text:'Avión'},
              {value:'Bus',text:'Bus'},
              {value:'Auto',text:'Auto'},
        ],
        clients:[],
        client:{id:1,full_name: 'CLIENTES VARIOS - 00000000'},
        invoices_customers:[],
        invoice_customer:{id:1,full_name: 'CLIENTES VARIOS - 00000000'},
        
        passenger:{
          id_passenger:0,
          id_room_control:0,
          document_type:'1',
          document_number:'',
          name:'',
          state:0,
        },

        passengers:[],
        error_document_number:'',
        passegner_document_type:[
          {value: 1, text:'DNI'},
          {value: 6, text:'RUC'},
          {value: 4, text:'CARNET DE EXTRANJERIA'},
          {value: 7, text:'PASAPORTE'},
          {value: 0, text:'OTROS'},
        ],
        errors:{
          id_client:false,
          invoice_customer:false,
          start_date:false,
          final_date:false,
          price:false,
          quantity:false,
          room_price:false,
          breakfasts:false,
          reserve_medium:false,
          transport:false,
          reason_for_stay:false,
          way_to_pay:false,

          document_number:false,
          name_passenger:false,
        },
        validate: false,
    };
  },
  mounted () {
      EventBus.$on('ModalRoomShow', (id_room,id_room_control) => {
        this.isLoading = true;
        this.id_room_control = id_room_control;
        this.id_room = id_room;
        this.room_control= {
          id_room:0,
          id_type_room:0,
          id_user:0,
          id_client:'',
          date:'',
          hour:'',
          start_date:'',
          final_date:'',
          price:'',
          breakfasts:0,
          quantity:'',
          room_price:0,
          check_inn_price:0,
          check_out_price:0,

          reserve_medium:'',
          company:'',
          voucher:'',
          transport:'',
          reason_for_stay:'',
          number_of_guests:'1',
          goin_to:'',
          way_to_pay:'',
          goin_to:'',
          observation:'',

          passengers:[],
        };
        this.ViewRoom();
        this.ViewRoomControl();
        this.modal_room = true;
      });




  },
  methods: {
      search(search, loading) {
        let me = this;
        let url = this.url_base + "search-clients/" + search;
        if (search !== "") {
          loading(true);
          axios({
            method: "GET",
            url: url,
          }).then(function (response) {
                me.clients = response.data;
                loading(false);
          })
        }
      },
      search_invoice_customer(search, loading) {
        let me = this;
        let url = this.url_base + "search-clients/" + search;
        if (search !== "") {
          loading(true);
          axios({
            method: "GET",
            url: url,
          }).then(function (response) {
                me.invoices_customers = response.data;
                loading(false);
          })
        }
      },
       setSelectedClient(value) {
          if (value != null) {
            this.invoice_customer = value;
          }
      },
      ViewRoom,
      ViewRoomControl,
      CalculateEndDate,
      EditRoomControl,
      Validate,
      ShowModalClient,

      ModalChangeRoom,
      ListFreeRoom,
      ConfirmChangeRoom,
      ChangeRoom,

      CodeDocumentType,
      AddPassenger,
      DeletePassenger,
      SearchPassenger,
      ...mapActions('RoomControl',['mLoadListRoomControl']),
  },
  computed: {
    ...mapState(["url_base"]),
    // ...mapState('RoomControl',['room_control_rc']),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    id_user: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.id_user;
    },

  },
};

//Ver habitacion
function ViewRoom() {
 
  let me = this;
  let url = this.url_base + "room/view/" + this.id_room;

  axios({
    method: "GET",
    url: url,
    headers: {
      token: this.token,
      module: this.module,
      role: this.role,
    },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.room.id_room = response.data.result.id_room;
        me.room.id_type_room = response.data.result.id_type_room;
        me.room.room_number = response.data.result.room_number;
        me.room.photo = response.data.result.photo;
        me.room.price = response.data.result.price;
        me.room.beds = response.data.result.beds;
        me.room.price = response.data.result.price;
        me.room.description = response.data.result.description;
        me.room.type_room_name = response.data.result.type_room_name;

      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
    })


}

function ViewRoomControl() {
  let me = this;
  let url = this.url_base + "room-control/view/" + this.id_room_control;

  axios({
    method: "GET",
    url: url,
    headers: {
      token: this.token,
      module: this.module,
      role: this.role,
    },
  })
    .then(function (response) {
      if (response.data.status == 200) {

        me.client = {id : response.data.result.id_client, full_name: response.data.result.client_name + ' - ' + response.data.result.client_document_number};
        me.invoice_customer = {id : response.data.result.invoice_customer, full_name: response.data.result.customer_name + ' - ' + response.data.result.customer_document_number};

        me.room_control.client_name = response.data.result.client_name;
        me.room_control.client_document_number = response.data.result.client_document_number;
        me.room_control.id_client = response.data.result.id_client;
        me.room_control.id_room = response.data.result.id_room;
        me.room_control.id_type_room = response.data.result.id_type_room;
        me.room_control.id_room_control = response.data.result.id_room_control;
        me.room_control.id_user = response.data.result.id_user;
        me.room_control.price = response.data.result.price;
        me.room_control.maximum_guest = response.data.result.maximum_guest;
        me.room_control.breakfasts = response.data.result.breakfasts;
        me.room_control.quantity = response.data.result.quantity;
        me.room_control.start_date = response.data.result.start_date;
        me.room_control.final_date = response.data.result.final_date;
        me.room_control.date = moment(response.data.result.date).local().format("YYYY-MM-DD");
        me.room_control.start_hour = response.data.result.start_hour;
        me.room_control.check_inn_price = response.data.result.check_inn_price;
        me.room_control.check_out_price = response.data.result.check_out_price;
        me.room_control.room_price = response.data.result.room_price;
        me.room_control.observation = response.data.result.observation;

        me.room_control.reserve_medium = response.data.result.reserve_medium;
        me.room_control.company = response.data.result.company;
        me.room_control.voucher = response.data.result.voucher;
        me.room_control.transport = response.data.result.transport;
        me.room_control.reason_for_stay = response.data.result.reason_for_stay;
        me.room_control.number_of_guests = response.data.result.number_of_guests;
        me.room_control.goin_to = response.data.result.goin_to;
        me.room_control.way_to_pay = response.data.result.way_to_pay;

        me.passengers = response.data.passengers;

        me.isLoading = false;
      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
    })

}

//Calcular fecha final
function CalculateEndDate() {

  this.room_control.final_date = moment(this.room_control.start_date, "YYYY-MM-DD").add('days',parseInt(this.room_control.quantity)).local().format("YYYY-MM-DD");
  let room_price = parseFloat(this.room_control.quantity) * parseFloat(this.room_control.price); 
  this.room_control.room_price = room_price + parseFloat(this.room_control.check_inn_price) + parseFloat(this.room_control.check_out_price);

  this.room_control.price = parseFloat(this.room_control.price).toFixed(2);
  this.room_control.check_inn_price = parseFloat(this.room_control.check_inn_price).toFixed(2);
  this.room_control.check_out_price = parseFloat(this.room_control.check_out_price).toFixed(2);
  this.room_control.room_price = this.room_control.room_price.toFixed(2);

}
//modal new cliente
function ShowModalClient() {
  
  EventBus.$emit('ModalClient');
}

//agregar control habitacion
function EditRoomControl(_this) {

 
  let me = _this;

  me.room_control.id_client = me.client.id;
  me.room_control.invoice_customer = me.invoice_customer.id;

  let url = me.url_base + "room-control/edit";
  let data = me.room_control;

  

  axios({
    method: "PUT",
    url: url,
    data: data,
    headers: {
      "Content-Type": "application/json",
      token: me.token,
      module: me.module,
      role: me.role,
    },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.mLoadListRoomControl();
        Swal.fire("Sistema", "Se ha actualizado la estadia", "success");
        me.modal_room = false;
      } else if  (response.data.status == 409) {
        me.mLoadListRoomControl();
        Swal.fire("Sistema", "La habitacion seleccionado ya no se encuentra disponible", "error");
        me.modal_room = false;
      }else{
          Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    });
}


function ModalChangeRoom() {
  this.modal_change_room = true;
  this.ListFreeRoom();
}

function ListFreeRoom() {

  let me = this;
  let url = me.url_base + "room-control/room-free";
 
  axios({
    method: "GET",
    url: url,
    headers: {
      "Content-Type": "application/json",
      token: me.token,
      module: me.module,
      role: me.role,
    },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.free_room = [];
        me.free_room.push({text:'Seleccione una habitación',value:''})
        for (let index = 0; index < response.data.result.length; index++) {
          const element = response.data.result[index];
          me.free_room.push({text:element.room_number,value:element.id_room})
        }
      }else{
          Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
    })
    .catch((error) => {
     
    });
}


function ConfirmChangeRoom() {

  
  if (this.change_room.id_room.length == 0) {
    Swal.fire("Sistema", "Seleccione una habitación", "error");
    return false;
  }

  
  let me = this;
   if (!this.validate) {
    Swal.fire({
      title: "Esta seguro de cambiar la habitación?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Estoy de acuerdo!",
    }).then((result) => {
      if (result.value) {
        me.ChangeRoom(me);
      }
    });
  }

}

function ChangeRoom(_this) {
  let me = _this;

  let url = me.url_base + "room-control/change-room";
  let data = {
    id_room_control : me.room_control.id_room_control,
    id_room : me.change_room.id_room,
  }

  axios({
    method: "PUT",
    url: url,
    data: data,
    headers: {
      "Content-Type": "application/json",
      token: me.token,
      module: me.module,
      role: me.role,
    },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.mLoadListRoomControl();
        me.modal_room = false;
        me.modal_change_room = false;
        Swal.fire("Sistema", "Se ha cambiado se habitación correctamente", "success");
      }else{
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    });
}


function Validate() {

  this.errors.id_client = this.client == null || this.client == {} ? true : false;
  this.errors.invoice_customer = this.invoice_customer == null ? true : false;
  this.errors.start_date = this.room_control.start_date.length == 0 ? true : false;
  this.errors.final_date = this.room_control.final_date.length == 0 ? true : false;
  this.errors.price = this.room_control.price.length == 0 ? true : false;
  this.errors.quantity = this.room_control.quantity.length == 0 ? true : false;
  this.errors.room_price = this.room_control.room_price.length == 0 ? true : false;

  this.errors.breakfasts = this.room_control.breakfasts.length == 0 ? true : false;
  this.errors.reserve_medium = this.room_control.reserve_medium.length == 0 ? true : false;
  this.errors.transport = this.room_control.transport.length == 0 ? true : false;
  this.errors.reason_for_stay = this.room_control.reason_for_stay.length == 0 ? true : false;
  this.errors.way_to_pay = this.room_control.way_to_pay.length == 0 ? true : false;
  
  
 
  if (this.errors.id_client) { this.validate = true; return false;}else{ this.validate = false; }
  if (this.errors.invoice_customer) { this.validate = true; return false;}else{ this.validate = false; }
  if (this.errors.start_date) { this.validate = true; return false;}else{ this.validate = false; }
  if (this.errors.final_date) { this.validate = true; return false;}else{ this.validate = false; }
  if (this.errors.price) { this.validate = true; return false;}else{ this.validate = false; }
  if (this.errors.quantity) { this.validate = true; return false;}else{ this.validate = false; }
  if (this.errors.room_price) { this.validate = true; return false;}else{ this.validate = false; }

  if (this.errors.breakfasts) { this.validate = true; return false;}else{ this.validate = false; }
  if (this.errors.reserve_medium) { this.validate = true; return false;}else{ this.validate = false; }
  if (this.errors.transport) { this.validate = true; return false;}else{ this.validate = false; }
  if (this.errors.reason_for_stay) { this.validate = true; return false;}else{ this.validate = false; }
  if (this.errors.way_to_pay) { this.validate = true; return false;}else{ this.validate = false; }
 

  let me = this;

  if (!this.validate) {
    Swal.fire({
      title: "Esta seguro de editar la habitación?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Estoy de acuerdo!",
    }).then((result) => {
      if (result.value) {
        me.EditRoomControl(me);
      }
    });
  }

  
}



function SearchPassenger() {

  
  let me = this;
  if (me.passenger.document_type == 1) {
      if (me.passenger.document_number.length == 8) {
        me.errors.document_number = false;

        ApiQuery.SearchDni(me.passenger.document_number).then((data) => {
          if (data.status == 200) {
            me.passenger.name = data.razon_social;
          }else{
            me.passenger.name = '';
          }
            
        });

      }else{
        me.errors.document_number = true;
        me.error_document_number = 'El nro de ducumento debe contener 8 digitos';
      }
  }

  if (me.passenger.document_type == 6) {
      if (me.passenger.document_number.length == 11) {
        me.errors.document_number = false;
        
        ApiQuery.SearchRuc(me.passenger.document_number).then((data) => {
          if (data.status == 200) {
            me.passenger.name = data.razon_social;
          }else{
            me.passenger.name = '';
          }
            
        });

      }else{
        me.errors.document_number = true;
        me.error_document_number = 'El nro de ducumento debe contener 11 digitos';
      }
  }
}
function AddPassenger() {

  if (this.passenger.document_type == 1) {
    this.errors.document_number = this.passenger.document_number.length != 8 ? true : false;
    this.error_document_number = 'El nro de ducumento debe contener 8 digitos';
  }else if(this.passenger.document_type == 6){
    this.errors.document_number = this.passenger.document_number.length != 11 ? true : false;
    this.error_document_number = 'El nro de ducumento debe contener 11 digitos';
  }else if(this.passenger.document_type == 4){
    this.errors.document_number = this.passenger.document_number.length != 12 ? true : false;
    this.error_document_number = 'El nro de ducumento debe contener 12 digitos';
  }else if(this.passenger.document_type == 7){
    this.errors.document_number = this.passenger.document_number.length != 12 ? true : false;
    this.error_document_number = 'El nro de ducumento debe contener 12 digitos';
  }else{
    this.errors.document_number = this.passenger.document_number.length == 0 ? true : false;
    this.error_document_number = 'Ingrese un nro de documento';
  }
  
  this.errors.name_passenger = this.passenger.name.length == 0 ? true : false
     
  

  if (this.errors.document_number || this.errors.name_passenger) {
    return false;
  }

  let me = this;
  let url = me.url_base + "room-control/add-passenger";
  me.passenger.id_room_control = me.room_control.id_room_control;
  let data = me.passenger;

  axios({
    method: "POST",
    url: url,
    data: data,
    headers: {
      "Content-Type": "application/json",
      token: me.token,
      module: me.module,
      role: me.role,
    },
  })
    .then(function (response) {
      if (response.data.status == 201) {
          me.passengers.push(response.data.result)
          me.passenger.document_number ='';
          me.passenger.name = '';
      }
    })

   

}
function CodeDocumentType(code) {
  return CodeToName.NameDocumentType(code);
}

function DeletePassenger(id_passenger) {

  let me = this;
  let url = this.url_base + "room-control/delete-passenger/" + id_passenger;
  axios({
    method: "delete",
    url: url,
    headers: {
      token: this.token,
      module: this.module,
      role: this.role,
    },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        for (var i = 0; i < me.passengers.length; i++) {
          if (me.passengers[i].id_passenger == id_passenger) {
            me.passengers.splice(i, 1);
            break;
          }
        }
      }
    })
}


</script>