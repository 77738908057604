<template>
  <div>
    <b-modal size="xl" id="modal_room" hide-footer v-model="modal_room_control_detail" class="w-100" title="DETALLE DE ESTADIA">


      <b-card no-body>
        <b-tabs card fill content-class="mt-3" align="center">
          <b-tab title="Estadia" active>

            <b-row>
              <b-col md="12">
                <h4 class="text-info">{{room_control.client_name +' - '+ room_control.client_document_number}}</h4>
              </b-col>
            </b-row>


            <div class="table-responsive w-100 pt-3">
                  <table class="table  table-hover table-bordered table-lg mt-lg mb-0">
                    <thead>
                      <tr>
                        <th  class="text-center">Nro</th>
                        <th  class="text-center">Tipo</th>
                        <th  class="text-center">Fecha de Llegada</th>
                        <th  class="text-center">Fecha de Salida</th>
                        <th  class="text-center">Precio</th>
                        <th  class="text-center">Noches</th>
                        <th  class="text-center">Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="text-center">{{ room.room_number }}</td>
                        <td class="text-left">{{ room.type_room_name }}</td>
                        <td class="text-center">{{ room_control.start_date }}</td>
                        <td class="text-center">{{ room_control.final_date }}</td>
                        <td class="text-right">{{ room_control.price }}</td>
                        <td class="text-center">{{ room_control.quantity }}</td>
                        <td class="text-right">{{ room_control.room_price }}</td>
                      </tr>
                    </tbody>
                  </table>

            </div>

            <b-row>
              <b-col class="mt-3" md="12">
                 <strong class="mt-4">Observación : {{room_control.observation}}</strong>
              </b-col>
            </b-row>

           
                  


          </b-tab>
          
          <b-tab title="Frigobar">

            <b-row>
              <b-col md="12">
                <h4 class="text-info">{{room_control.client_name +' - '+ room_control.client_document_number}}</h4>
              </b-col>
            </b-row>

             <div class="table-responsive mt-3">
                <table class="table  table-hover table-bordered table-lg mt-lg mb-0">
                  <thead>
                    <tr>
                      <th width="5%" class="text-center">#</th>
                      <th width="14%" class="text-center">Categoria</th>
                      <th width="8%" class="text-center">Codigo</th>
                      <th width="45%" class="text-center">Producto</th>
                      <th width="8%" class="text-center">Cantidad</th>
                      <th width="10%" class="text-center">P. Unit</th>
                      <th width="10%" class="text-center">P. Total</th>
                    </tr>
                  </thead>
                  <tbody v-for="(item, it) in room_control_minibar" :key="it">
                    <tr>
                      <td class="text-center">{{ it + 1 }}</td>
                      <td class="text-left">{{ item.code }}</td>
                      <td class="text-left">{{ item.code }}</td>
                      <td class="text-left">{{ item.name }}</td>
                      <td class="text-center">{{ item.quantity }}</td>
                      <td class="text-right">{{ item.unit_price }}</td>
                      <td class="text-right">{{ item.total_price }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
          </b-tab>
          <b-tab title="Consumo">

            <b-row>
              <b-col md="12">
                <h4 class="text-info">{{room_control.client_name +' - '+ room_control.client_document_number}}</h4>
              </b-col>
            </b-row>

            <div class="table-responsive mt-3">
                <table class="table table-hover table-bordered table-lg mt-lg mb-0">
                  <thead>
                    <tr>
                      <th width="5%" class="text-center">#</th>
                      <th width="14%" class="text-center">Categoria</th>
                      <th width="8%" class="text-center">Codigo</th>
                      <th width="45%" class="text-center">Producto</th>
                      <th width="8%" class="text-center">Cantidad</th>
                      <th width="10%" class="text-center">P. Unit</th>
                      <th width="10%" class="text-center">P. Total</th>
                      
                    </tr>
                  </thead>
                  <tbody v-for="(item, it) in room_control_consumer" :key="it">
                    <tr>
                      <td class="text-center">{{ it + 1 }}</td>
                      <td class="text-left">{{ item.code }}</td>
                      <td class="text-left">{{ item.code }}</td>
                      <td class="text-left">{{ item.name }}</td>
                      <td class="text-center">{{ item.quantity }}</td>
                      <td class="text-right">{{ item.unit_price }}</td>
                      <td class="text-right">{{ item.total_price }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
          </b-tab>
          <b-tab title="Abonos">

            <b-row>
              <b-col md="12">
                <h4 class="text-info">{{room_control.client_name +' - '+ room_control.client_document_number}}</h4>
              </b-col>
            </b-row>

            <div class="table-responsive mt-3">
                <table class="table table-hover table-bordered table-lg mt-lg mb-0">
                  <thead>
                    <tr>
                      <th width="5%" class="text-center">#</th>
                      <th width="8%" class="text-center">Fecha</th>
                      <th width="12%" class="text-center">TC</th>
                      <th width="50%" class="text-center">Cliente</th>
                      <th width="5%" class="text-center">Moneda</th>
                      <th width="7%" class="text-center">Total</th>
                      <th width="12%" class="text-center">Usuario</th>
                      <th width="7%" class="text-center">Estado</th>
                    </tr>
                  </thead>
                  <tbody v-for="(item, index) in payment_account" :key="index">
                    <tr>
                        <td class="text-center">{{ index + 1 }}</td>
                        <td class="text-center">{{ item.broadcast_date }}</td>
                        <td class="text-center">{{ CodeInvoice(item.voucher_type) +' '+item.serie+'-'+item.number }}</td>
                        <td class="text-left">{{ item.name + ' - '+ item.document_number }}</td>
                        <td class="text-center">{{ item.coin }}</td>
                        <td class="text-right">{{ item.total }}</td>
                        <td class="text-right">{{ item.email }}</td>
                        <td class="text-center">
                          <b-badge v-if="item.state == 1" variant="success">Activo</b-badge>
                          <b-badge v-if="item.state == 0" variant="danger">Anulado</b-badge>
                        </td>
                      
                    </tr>
                        
                  </tbody>
                </table>
              </div>
          </b-tab>
          <b-tab title="Pagos">

            <b-row>
              <b-col md="12">
                <h4 class="text-info">{{room_control.client_name +' - '+ room_control.client_document_number}}</h4>
              </b-col>
            </b-row>


            <div class="table-responsive mt-3">
              <table class="table table-hover table-bordered">
                <thead>
                  <tr>
                    <th width="3%" class="text-center">#</th>
                    <th width="10%" class="text-center">Fecha</th>
                    <th width="13%" class="text-center">Comprobante</th>
                    <th width="49%" class="text-center">Cliente</th>
                    <th width="8%" class="text-center">Total</th>
                    <th width="10%" class="text-center">Usuario</th>
                    <th width="10%" class="text-center">Estado</th>
               
                  </tr>
                </thead>
                <tbody v-for="(item, it) in payments" :key="item.id_payment">
                  <tr>
                    <td class="text-center">{{ it + 1 }}</td>
                    <td class="text-center"> {{ item.broadcast_date }}</td>
                    <td class="text-center"> {{ CodeInvoice(item.voucher_type)+ ' '+item.serie+'-'+item.number }}</td>
                    <td class="text-left"> {{ item.name + ' - '+item.document_number }}</td>
                    <td class="text-right"> {{ item.total }} </td>
                    <td class="text-right"> {{ item.email }} </td>
                    <td class="text-center">
                          <b-badge v-if="item.state == 0" variant="danger">XML</b-badge>
                          <b-badge v-if="item.state == 1" variant="success">Girado</b-badge>
                          <b-badge v-if="item.state == 2" variant="info">Emitido</b-badge>
                          <b-badge v-if="item.state == 3" variant="success">Aceptado</b-badge>
                          <b-badge v-if="item.state == 4" variant="danger">Rechazado</b-badge>
                          <b-badge v-if="item.state == 5" variant="warning">Anulado</b-badge>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

          </b-tab>
          <b-tab title="Resumen">

            <b-row>
              <b-col md="12">
                <h4 class="text-info">{{room_control.client_name +' - '+ room_control.client_document_number}}</h4>
              </b-col>
            </b-row>

            <div class="table-responsive mt-3">
                <table class="table table-hover table-bordered table-lg mt-lg mb-0">
                  <thead>
                    <tr>
                      <th width="5%" class="text-center">#</th>
                      <th width="85%" class="text-center">Concepto</th>
                      <th width="10%" class="text-center">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr  >
                        <td class="text-center">1</td>
                        <td class="text-left"><strong>Hab. {{ room.room_number }}</strong>  ({{ room.type_room_name }}) x {{ room_control.quantity }} Noche(s) </td>
                        <td class="text-right">{{ room_control.room_price }}</td>
                    </tr>
                    <tr  >
                        <td class="text-center">2</td>
                        <td class="text-left">Frogibar </td>
                        <td class="text-right">{{ room_control.minibar_price }}</td>
                    </tr>
                    <tr>
                        <td class="text-center">3</td>
                        <td class="text-left">Consumo </td>
                        <td class="text-right">{{ room_control.consumer_price }}</td>
                    </tr>
                    <tr>
                        <td class="text-center">4</td>
                        <td class="text-left">Abonos </td>
                        <td class="text-right">{{ room_control.total_paid }}</td>
                    </tr>
                    <tr>
                        <td class="text-center">5</td>
                        <td class="text-left">Saldo </td>
                        <td class="text-right">{{ room_control.balance }}</td>
                    </tr>
                        
                  </tbody>
                </table>
              </div>
           
          </b-tab>
        </b-tabs>
      </b-card>
    </b-modal>
      
    <LoadingComponent :is-visible="isLoading"/>
  </div>
</template>


<style >


</style>
 
<script>
///import vue select
import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import "vue-select/src/scss/vue-select.scss";

import LoadingComponent from './../../pages/Loading'

const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
var moment = require("moment");
import { mapState,mapMutations,mapActions } from "vuex";
import EventBus from "@/assets/js/EventBus";
import CodeToName from "@/assets/js/CodeToName";

// import ModalClientRoom from './../../components/ModalClient'

export default {
  name: "ModalRoomControl",
  components:{
    // ModalClientRoom,
    vSelect,
    LoadingComponent,
  },
  data() {
    return {
        isLoading: false,
        fullPage: true,

        module: 'RoomControl',
        role:3,

        modal_room_control_detail:false,
        id_room: 0,
        room: {
          id_room:0,
          id_type_room:0,
          room_number:'',
          photo:'',
          price:0,
          description:'',
          type_room_name:'',
        },
        room_control:{
          client_name:'',
          client_document_number:'',
          id_room:0,
          id_type_room:0,
          id_user:0,
          id_client:'',
          date:'',
          hour:'',
          start_date:'',
          final_date:'',
          price:'',
          quantity:'',
          room_price:'',
          observation:'',
        },

        clients:[],
        client:null,


        room_control_minibar: [],
        room_control_consumer:[],
        payment_account: [],
        payments: [],
        payment: [],
        detail:{
          room: (0).toFixed(2),
          minibar: (0).toFixed(2),
          payment_account: (0).toFixed(2),
          balance: (0).toFixed(2),

        },

        errors:{
          id_client:false,
          start_date:false,
          final_date:false,
          price:false,
          quantity:false,
          room_price:false,
        },
        validate: false,
    };
  },
  mounted () {
      EventBus.$on('ModalRoomControlDetailShow', (id_room,id_room_control) => {
        this.id_room_control = id_room_control;
        this.id_room = id_room;
        this.isLoading = true;
        this.ViewRoom();
        this.ViewRoomControl();
        this.ListRoomControlMinibar();
        this.ListRoomControlConsumer();
        this.ListPaymentAccount();
        this.ListPayments();
        this.modal_room_control_detail= true;
      });




  },
  methods: {
      search(search, loading) {
        let me = this;
        let url = this.url_base + "search-clients/" + search;
        if (search !== "") {
          loading(true);
          axios({
            method: "GET",
            url: url,
          }).then(function (response) {
                me.clients = response.data;
                loading(false);
          })
        }
      },
      ViewRoom,
      ViewRoomControl,
      CalculateEndDate,
      EditRoomControl,
      Validate,
      ShowModalClient,
      ListRoomControlMinibar,
      ListRoomControlConsumer,
      ListPaymentAccount,
      CodeInvoice,
      ListPayments,
      ...mapActions('RoomControl',['mLoadListRoomControl']),
  },
  computed: {
    ...mapState(["url_base"]),
    // ...mapState('RoomControl',['room_control_rc']),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    id_user: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.id_user;
    },

  },
};

function ListPayments() {

  let me = this;
  let url = this.url_base + "payment/list-by-room-control/"+this.id_room_control;

  axios({
    method: "GET",
    url: url,
    headers: {
      token: this.token,
      module: this.module,
      role: this.role,
    },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.payments = response.data.result;
        me.isLoading = false;
      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
    })
}
function CodeInvoice(code) {
  return CodeToName.CodeInvoice(code);
}

function ListPaymentAccount() {

  let me = this;
  let url = this.url_base + "payment-account/list-all/"+this.id_room_control;

  axios({
    method: "GET",
    url: url,
    headers: {
      token: this.token,
      module: this.module,
      role: this.role,
    },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.payment_account = response.data.result;
        
      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
    })
}


//Ver habitacion
function ViewRoom() {


  let me = this;
  let url = this.url_base + "room/view/" + this.id_room;

  axios({
    method: "GET",
    url: url,
    headers: {
      token: this.token,
      module: this.module,
      role: this.role,
    },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.room.id_room = response.data.result.id_room;
        me.room.id_type_room = response.data.result.id_type_room;
        me.room.room_number = response.data.result.room_number;
        me.room.photo = response.data.result.photo;
        me.room.price = response.data.result.price;
        me.room.beds = response.data.result.beds;
        me.room.maximum_guest = response.data.result.maximum_guest;
        me.room.description = response.data.result.description;
        me.room.type_room_name = response.data.result.type_room_name;
    

      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
    })


}

function ViewRoomControl() {
  let me = this;
  let url = this.url_base + "room-control/view/" + this.id_room_control;

  axios({
    method: "GET",
    url: url,
    headers: {
      token: this.token,
      module: this.module,
      role: this.role,
    },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.client = {id : response.data.result.id_client, full_name: response.data.result.client_name + ' - ' + response.data.result.client_document_number};

        me.room_control.client_name = response.data.result.client_name;
        me.room_control.client_document_number = response.data.result.client_document_number;
        me.room_control.id_client = response.data.result.id_client;
        me.room_control.id_room = response.data.result.id_room;
        me.room_control.id_type_room = response.data.result.id_type_room;
        me.room_control.id_room_control = response.data.result.id_room_control;
        me.room_control.id_user = response.data.result.id_user;
        me.room_control.price = response.data.result.price;
        me.room_control.quantity = response.data.result.quantity;
        me.room_control.start_date = response.data.result.start_date;
        me.room_control.final_date = response.data.result.final_date;
        me.room_control.date = moment(response.data.result.date).local().format("YYYY-MM-DD");
        me.room_control.hour = response.data.result.hour;
        me.room_control.room_price = response.data.result.room_price;
        me.room_control.minibar_price = response.data.result.minibar_price;
        me.room_control.consumer_price = response.data.result.consumer_price;
        me.room_control.total = response.data.result.total;
        me.room_control.total_paid = response.data.result.total_paid;
        me.room_control.balance = parseFloat(response.data.result.total) - parseFloat(response.data.result.total_paid);
        me.room_control.balance = me.room_control.balance.toFixed(2);
        me.room_control.observation = response.data.result.observation;
        me.room_control.state = response.data.result.state;
    
      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
    })

}

//Calcular fecha final
function CalculateEndDate() {
  
  
  this.room_control.final_date = moment(this.room_control.start_date, "YYYY-MM-DD HH:mm:ss").add(parseInt(this.room_control.quantity) , 'quantity').local().format("YYYY-MM-DD HH:mm:ss");
  this.room_control.room_price = parseFloat(this.room_control.quantity) * parseFloat(this.room_control.price); 

  this.room_control.price = parseFloat(this.room_control.price).toFixed(2);
  this.room_control.room_price = this.room_control.room_price.toFixed(2);

}
//modal new cliente
function ShowModalClient() {
  
  EventBus.$emit('ModalClient');
}

//agregar control habitacion
function EditRoomControl() {

  this.Validate();
  if (this.validate) {
    return false;
  }

  this.room_control.id_client = this.client.id;
  let me = this;
  let url = this.url_base + "room-control/edit";
  let data = this.room_control;

  

  axios({
    method: "PUT",
    url: url,
    data: data,
    headers: {
      "Content-Type": "application/json",
      token: this.token,
      module: this.module,
      role: this.role,
    },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.mLoadListRoomControl();
        Swal.fire("Sistema", "Se ha actualizado la estadia", "success");
      } else if  (response.data.status == 409) {
        me.mLoadListRoomControl();
        Swal.fire("Sistema", "La habitacion seleccionado ya no se encuentra disponible", "error");
        me.modal_room = false;
      }else{
          Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    });
}


function ListRoomControlMinibar() {

  let me = this;
  let url = this.url_base + "room-control-detail/list/"+this.id_room_control+"/Minibar";

  axios({
    method: "GET",
    url: url,
    headers: {
      token: this.token,
      module: this.module,
      role: this.role,
    },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.room_control_minibar = response.data.result;
      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
    })
}


function ListRoomControlConsumer() {

  let me = this;
  let url = this.url_base + "room-control-detail/list/"+this.id_room_control+"/Consumer";

  axios({
    method: "GET",
    url: url,
    headers: {
      token: this.token,
      module: this.module,
      role: this.role,
    },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.room_control_consumer = response.data.result;
      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
    })
}

function Validate() {

  this.validate = false;
  this.errors.id_client = this.client == null || this.client == {} ? true : false;
  this.errors.final_date = this.room_control.start_date.length == 0 ? true : false;
  this.errors.price = this.room_control.start_date.length == 0 ? true : false;
  this.errors.quantity = this.room_control.start_date.length == 0 ? true : false;
  this.errors.room_price = this.room_control.start_date.length == 0 ? true : false;

  if (this.errors.id_client) { this.validate = true; return false;}else{ this.validate = false; }
  if (this.errors.final_date) { this.validate = true; return false;}else{ this.validate = false; }
  if (this.errors.price) { this.validate = true; return false;}else{ this.validate = false; }
  if (this.errors.quantity) { this.validate = true; return false;}else{ this.validate = false; }
  if (this.errors.room_price) { this.validate = true; return false;}else{ this.validate = false; }

}


</script>