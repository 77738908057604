<template>
  <div>
    <b-modal size="xl" hide-footer v-model="modal_room_expire" class="w-100" title="RESERVAS FUTURAS">
      <b-row>
            <b-col md="12">
                <div class="table-responsive mt-3">
                  <table class="table table-hover table-bordered">
                    <thead>
                      <tr>
                        
                        <th width="5%" class="text-center">#</th>
                        <th width="10%" class="text-center">Hab.</th>
                        <th width="55%" class="text-center">Cliente</th>
                        <th width="10%" class="text-center">F. Llegada</th>
                        <th width="10%" class="text-center">Noches</th>
                        <th width="10%" class="text-center">F. Salida</th>
                        <th width="10%" class="text-center">Total</th>
                      </tr>
                    </thead>
                    <tbody v-for="(item, it) in reservations" :key="it">
                      <tr>
                        <td class="text-center">{{ it + 1 }}</td>
                        <td class="text-center">{{ item.room_number }}</td>
                        <td class="text-left">{{ item.name + ' -' +item.document_number }}</td>
                        <td class="text-center">{{ item.from }}</td>
                        <td class="text-center">{{ item.nights }}</td>
                        <td class="text-center">{{ item.to }}</td>
                        <td class="text-right">{{ item.total }}</td>
                 
                 
                      </tr>
                    </tbody>
                  </table>
                </div>
            </b-col>
      </b-row>
    </b-modal>

    <LoadingComponent :is-visible="isLoading"/>
  </div>
</template>
<style>

.table th, .table td {
    padding: 0.5rem;
    vertical-align: top;
    border-top: 1px solid;
    border-top-color: #d8dbe0;
    vertical-align: middle;
}

</style>



<script>
///import vue select
import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import "vue-select/src/scss/vue-select.scss";

import LoadingComponent from './../../pages/Loading'

const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState,mapMutations,mapActions } from "vuex";
import EventBus from "@/assets/js/EventBus";

export default {
  name: "ModalRoomExpire",
  components:{
    LoadingComponent
  },
  data() {
    return {
        isLoading: false,
        modal_room_expire: false,
        validate:false,
        reservations : [],
        module:'RoomControl',
        role:1
        
    };
  },
  mounted () {
      EventBus.$on('ModalReservedRoom', () => {
        this.isLoading = true;
        this.modal_room_expire = true;
        this.ListReservedRoom();
      });
  },
  methods: {
    ListReservedRoom,
  },
  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
  },

};

function ListReservedRoom() {
  let me = this;
  let url = me.url_base + "reservation/future-reservations";

  axios({
    method: "GET",
    url: url,
    headers: {
      token: me.token,
      module: me.module,
      role: me.role,
    },
  })
    .then(function (response) {
      if (response.data.status == 200) {
       me.reservations = response.data.result;
       me.isLoading = false;
      } else {
      
      }
    })
}

</script>
