<template>
  <div>
    <b-modal size="xl" id="modal_new_room_control" hide-footer v-model="modal_new_room_control" class="w-100" title="CHECK IN">
      <b-row>

        <b-card no-body class="col-md-12">
          <b-tabs card fill content-class="mt-3" align="center" class="w-100">
            <b-tab title="CHECK IN" active>
                 <b-col md="12">
                    <b-form @submit.prevent="Validate">
                    <b-row>
                
                      <b-col sm="12" md="6">
                        <b-form-group>
                          <label class="control-label">Cliente: <span class="badge badge-primary link" @click="ShowModalClient">NUEVO</span></label>
                          <v-select @input="setSelectedClient" placeholder="Seleccione un cliente" inputId="id" class="w-100" :filterable="false" label="full_name" v-model="client" @search="search" :options="clients"></v-select>
                          <small v-if="errors.id_client" class="form-text text-danger" >Seleccione un cliente</small>
                        </b-form-group>
                      </b-col>

                      <b-col sm="4" md="2"> 
                        <b-form-group>
                          <label class="control-label">Tipo Habitación:</label>
                          <input type="text" readonly v-model="room.type_room_name" class="form-control text-left">
                        </b-form-group>
                      </b-col>

                      <b-col sm="4" md="2"> 
                        <b-form-group>
                          <label class="control-label">Habitación:</label>
                          <input type="text" readonly v-model="room.room_number" class="form-control text-left">
                        </b-form-group>
                      </b-col>

             
                       

                      <b-col sm="4" md="2"> 
                        <b-form-group>
                          <label class="control-label">Tarifa :</label>
                          <input type="number" step="any" @change="CalculateEndDate"  v-model="room_control.price" class="form-control text-right">
                        </b-form-group>
                      </b-col>
                      

                      <b-col sm="4" md="2">
                        <b-form-group>
                          <label class="control-label">Fecha Inicio:</label>
                          <input type="text" readonly v-model="room_control.date" class="form-control text-center">
                          <small v-if="errors.start_date" class="form-text text-danger" >Seleccione un fecha</small>
                        </b-form-group>
                      </b-col>

                      <b-col sm="4" md="2">
                        <b-form-group>
                          <label class="control-label">Hora Inicio:</label>
                          <input type="text" readonly step="1" v-model="room_control.hour" class="form-control text-center">
                        </b-form-group>
                      </b-col>

                      <b-col sm="3" md="2">
                        <b-form-group>
                          <label class="control-label">Noches:</label>
                          <input type="number" @change="CalculateEndDate" v-model="room_control.quantity" class="form-control text-center">
                          <small v-if="errors.quantity" class="form-text text-danger" >Ingrese el nro de noches</small>
                        </b-form-group>
                      </b-col>

                      <b-col sm="5" md="2">
                        <b-form-group>
                          <label class="control-label">Fecha de Salida:</label>
                          <input type="text" v-model="room_control.final_date" readonly class="form-control text-center">
                          <small v-if="errors.final_date" class="form-text text-danger" >Seleccione un fecha</small>
                        </b-form-group>
                      </b-col>


                      <b-col sm="3" md="2">
                        <b-form-group>
                          <label class="control-label">Early Check In:</label>
                          <input type="number" step="any" @change="CalculateEndDate" v-model="room_control.check_inn_price" class="form-control text-right">
                        </b-form-group>
                      </b-col>

                      <b-col sm="3" md="2">
                        <b-form-group>
                          <label class="control-label">Late Check Out:</label>
                          <input type="number" step="any" @change="CalculateEndDate" v-model="room_control.check_out_price" class="form-control text-right">
                        </b-form-group>
                      </b-col>


                      

                      <b-col sm="4" md="2">
                        <b-form-group>
                          <label class="control-label">Total:</label>
                          <input type="number" v-model="room_control.room_price" readonly step="any" class="form-control text-right">
                        </b-form-group>
                      </b-col>

                      <b-col sm="4" md="2">
                        <b-form-group>
                          <label class="control-label">Nro de Personas:</label>
                          <input type="number" v-model="room_control.number_of_guests" class="form-control text-center">
                          <small v-if="errors.number_of_guests" class="form-text text-danger" >Ingrese el nro de personas</small>
                        </b-form-group>
                      </b-col>

                      <b-col sm="4" md="2"> 
                        <b-form-group>
                          <label class="control-label">Desayunos :</label>
                          <input type="number" v-model="room_control.breakfasts" class="form-control text-center">
                          <small v-if="errors.breakfasts" class="form-text text-danger" >Ingrese el nro de desayunos</small>
                        </b-form-group>
                      </b-col>

                      <b-col md="2">
                        <b-form-group label="Medio de Reserva :">
                          <b-form-select ref="reserve_medium" v-model="room_control.reserve_medium" :options="reserve_medium"></b-form-select>
                          <small v-if="errors.reserve_medium" class="form-text text-danger" >Seleccione un medio de reserva</small>
                        </b-form-group>
                      </b-col>

                      <b-col md="2">
                        <b-form-group label="Transporte :">
                          <b-form-select ref="transport" v-model="room_control.transport" :options="transport"></b-form-select>
                          <small v-if="errors.transport" class="form-text text-danger" >Seleccione un medio de transporte</small>
                        </b-form-group>
                      </b-col>

                      <b-col md="2">
                        <b-form-group label="Motivo de Estadia :">
                          <b-form-select ref="reason_for_stay" v-model="room_control.reason_for_stay" :options="reason_for_stay"></b-form-select>
                          <small v-if="errors.reason_for_stay" class="form-text text-danger" >Seleccione un motivo</small>
                        </b-form-group>
                      </b-col>

                      

                      <b-col sm="4" md="2">
                        <b-form-group>
                          <label class="control-label">Proximo Destino:</label>
                          <input type="text" v-model="room_control.goin_to"  class="form-control text-left">
                        </b-form-group>
                      </b-col>

                      <b-col md="2">
                        <b-form-group label="Forma de Pago :">
                          <b-form-select ref="way_to_pay" v-model="room_control.way_to_pay" :options="way_to_pay"></b-form-select>
                          <small v-if="errors.way_to_pay" class="form-text text-danger" >Seleccione la forma de pago</small>
                        </b-form-group>
                      </b-col>

                      <b-col md="2">
                        <b-form-group label="Tipo Comprobante :">
                          <b-form-select ref="voucher" v-model="room_control.voucher" :options="voucher"></b-form-select>
                          <small v-if="errors.voucher" class="form-text text-danger" >Seleccione el tipo de comprobante</small>
                        </b-form-group>
                      </b-col>

                      <b-col sm="12" md="6">
                        <b-form-group>
                          <label class="control-label">Comprobante a nombre de : <span class="badge badge-primary link" @click="ShowModalClient">NUEVO</span></label>
                          <v-select placeholder="Seleccione un cliente" inputId="invoice_customer" class="w-100" :filterable="false" label="full_name" v-model="invoice_customer" @search="search_invoice_customer" :options="invoices_customers"></v-select>
                          <small v-if="errors.invoice_customer" class="form-text text-danger" >Seleccione un cliente</small>
                        </b-form-group>
                      </b-col>

                      <b-col md="12">
                        <b-form-group>
                          <label class="control-label">Observación:</label>
                          <textarea  rows="1" v-model="room_control.observation" class="form-control"></textarea>
                        </b-form-group>
                      </b-col>

                      <b-col md="6">
                        <b-form-group>
                            <b-button type="button" @click="GetReserva" :disabled="btn_reserva" variant="primary" class="form-control">Obtener Reserva</b-button>
                        </b-form-group>
                      </b-col>

                      <b-col md="6">
                        <b-form-group>
                          <button type="submit" class="btn btn-info form-control">REGISTRAR</button>
                        </b-form-group>
                      </b-col>

                
                    </b-row>
                    </b-form>
                  </b-col>
            </b-tab>
            <b-tab title="PASAJEROS">
                <div class="w-100">
             
                  <b-form @submit.prevent="AddPassenger">
                <b-row>
                    <b-col  md="2"> 
                      <b-form-group>
                        <label class="control-label">Tipo Documento:</label>
                        <b-form-select ref="transport" v-model="passenger.document_type" :options="passegner_document_type"></b-form-select>
                      </b-form-group>
                    </b-col>

                    <b-col  md="3"> 
                      <b-form-group label="Nro Documento :">
                        <b-input-group>
                          <b-form-input v-model="passenger.document_number" class="form-control" ></b-form-input>
                          <b-input-group-append>
                            <b-button variant="info" @click="SearchPassenger"><b-icon icon="search"></b-icon></b-button>
                          </b-input-group-append>
                        </b-input-group>
                        <small v-if="errors.document_number" class="form-text text-danger" >{{error_document_number}}</small>
                      </b-form-group>
                    </b-col>

                    <b-col  md="5"> 
                      <b-form-group>
                        <label class="control-label">Nombres :</label>
                        <b-input v-model="passenger.name" ></b-input>
                        <small v-if="errors.name_passenger" class="form-text text-danger" >Ingrese un nombre</small>
                      </b-form-group>
                    </b-col>
                    
        

                    <b-col  md="2">
                      <b-form-group>
                        <label class="control-label"> .</label>
                        <button type="submit" class="btn btn-info form-control">REGISTRAR</button>
                      </b-form-group>
                    </b-col>


                    <div class="table-responsive w-100 pt-3">
                      <table class="table  table-hover table-bordered table-lg mt-lg mb-0">
                        <thead>
                          <tr>
                            <th  width="5%" class="text-center">#</th>
                            <th  width="10%" class="text-center">Tipo Documento</th>
                            <th  width="15%" class="text-center">Nro Documento</th>
                            <th  width="65%" class="text-center">Nombres</th>
                            <th  width="5%" class="text-center">Acciones</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(item, it) in passengers" :key="it">
                            <td class="text-center">{{ it + 1 }}</td>
                            <td class="text-left">{{ CodeDocumentType(item.document_type) }}</td>
                            <td class="text-left">{{ item.document_number }}</td>
                            <td class="text-left">{{ item.name }}</td>
                            <td class="text-center">
                              <a @click="DeletePassenger(it)"><i class="fas fa-trash text-danger"></i></a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

              
                  </b-row>
                  </b-form>

                </div>
            </b-tab>
          </b-tabs>
        </b-card>


     

      </b-row>
    </b-modal>



  


    <!-- Modal Cliente -->
    <ModalClient/>

    <LoadingComponent :is-visible="isLoading"/>
  </div>
</template>


<style>



</style>
 
<script>
///import vue select
import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import "vue-select/src/scss/vue-select.scss";

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
var moment = require("moment");
import { mapState,mapMutations,mapActions } from "vuex";
import EventBus from "@/assets/js/EventBus";
import CodeToName from "@/assets/js/CodeToName";

import ModalClient from './../../components/ModalClient'
import LoadingComponent from './../../pages/Loading'
import ApiQuery from "@/assets/js/APIQuery";
export default {
  name: "ModalNewRoomControl",
  components:{
    ModalClient,
    vSelect,
    Loading,
    LoadingComponent
  },
  data() {
    return {

        isLoading: false,
        fullPage: true,

        module: 'RoomControl',
        role:2,

        modal_new_room_control:false,
        id_room: 0,
        room: {
          id_room:0,
          id_type_room:0,
          room_number:'',
          photo:'',
          price:0,
          description:'',
          type_room_name:'',
        },
        room_control:{
          id_room:0,
          id_type_room:0,
          id_user:0,
          id_client:'',
          date:'',
          hour:'',
          start_date:'',
          final_date:'',
          price:'',
          breakfasts:0,
          quantity:'',
          room_price:0,
          check_inn_price:0,
          check_out_price:0,

          reserve_medium:'',
          company:'',
          voucher:'',
          transport:'',
          reason_for_stay:'',
          number_of_guests:'1',
          goin_to:'',
          way_to_pay:'',
          goin_to:'',
          observation:'',

          passengers:[],
        },
        reservation:{
          id_reservation :0,
        },
        reserve_medium:[
              {value:'',text:'-- Seleccione ..'},
              {value:'Booking',text:'Booking'},
              {value:'Facebook',text:'Facebook'},
              {value:'Instagram',text:'Instagram'},
              {value:'WhatsApp',text:'WhatsApp'},
              {value:'Llamada',text:'Llamada'},
              {value:'Personal',text:'Personal'},
        ],
        voucher:[
              {value:'',text:'-- Seleccione ..'},
              {value:'01',text:'Factura'},
              {value:'03',text:'Boleta'},
              {value:'NS',text:'Nota de Servicio'},
        ],
        way_to_pay:[
             {value:'',text:'-- Seleccione ..'},
              {value:'Efectivo',text:'Efectivo'},
              {value:'Visa',text:'Visa'},
              {value:'Mastercard',text:'Mastercard'},
              {value:'American Express',text:'American Express'},
              {value:'Transferencia de Fondos',text:'Transferencia de Fondos'},

        ],
        reason_for_stay:[
              {value:'',text:'-- Seleccione ..'},
              {value:'Vacaciones',text:'Vacaciones'},
              {value:'Visita a Familiares',text:'Visita a Familiares'},
              {value:'Educación',text:'Educación'},
              {value:'Atención Médica',text:'Atención Médica'},
              {value:'Religión',text:'Religión'},
              {value:'Compras',text:'Compras'},
              {value:'Negocios',text:'Negocios'},
              {value:'Trabajo',text:'Trabajo'},
              {value:'Otros',text:'Otros'},
        ],
        transport:[
              {value:'',text:'-- Seleccione ..'},
              {value:'Avión',text:'Avión'},
              {value:'Bus',text:'Bus'},
              {value:'Auto',text:'Auto'},
        ],
        
        clients:[],
        client:{id:1,full_name: 'CLIENTES VARIOS - 00000000'},
        invoices_customers:[],
        invoice_customer:{id:1,full_name: 'CLIENTES VARIOS - 00000000'},
        passegner_document_type:[
          {value: 1, text:'DNI'},
          {value: 6, text:'RUC'},
          {value: 4, text:'CARNET DE EXTRANJERIA'},
          {value: 7, text:'PASAPORTE'},
          {value: 0, text:'OTROS'},
        ],
        passenger:{
          id_passenger:0,
          id_room_control:0,
          document_type:'1',
          document_number:'',
          name:'',
          state:0,
        },

        passengers:[],
        error_document_number: false,
        

        btn_reserva:false,
        errors:{
          id_client:false,
          invoice_customer:false,
          start_date:false,
          final_date:false,
          price:false,
          quantity:false,
          room_price:false,
          breakfasts:false,
          reserve_medium:false,
          transport:false,
          reason_for_stay:false,
          way_to_pay:false,

          document_number:false,
          name_passenger:false,
        },
        validate: false,
    };
  },
  mounted () {
      EventBus.$on('ModalNewRoomControlShow', (id_room) => {
        this.modal_new_room_control = true;
        this.id_room = id_room;

        this.room_control= {
          id_room:0,
          id_type_room:0,
          id_user:0,
          id_client:'',
          date:'',
          hour:'',
          start_date:'',
          final_date:'',
          price:'',
          breakfasts:0,
          quantity:'',
          room_price:0,
          check_inn_price:0,
          check_out_price:0,

          reserve_medium:'',
          company:'',
          voucher:'',
          transport:'',
          reason_for_stay:'',
          number_of_guests:'1',
          goin_to:'',
          way_to_pay:'',
          goin_to:'',
          observation:'',

          passengers:[],
        };
        this.ViewRoom();
      });




  },
  methods: {
      search(search, loading) {
        let me = this;
        let url = this.url_base + "search-clients/" + search;
        if (search !== "") {
          loading(true);
          axios({
            method: "GET",
            url: url,
          }).then(function (response) {
                me.clients = response.data;
                loading(false);
          })
        }
      },
      search_invoice_customer(search, loading) {
        let me = this;
        let url = this.url_base + "search-clients/" + search;
        if (search !== "") {
          loading(true);
          axios({
            method: "GET",
            url: url,
          }).then(function (response) {
                me.invoices_customers = response.data;
                loading(false);
          })
        }
      },
       setSelectedClient(value) {
          if (value != null) {
            this.invoice_customer = value;
          }
      },

      ViewRoom,
      CalculateEndDate,
      AddRoomControl,
      Validate,
      ShowModalClient,
      GetReserva,

      SearchPassenger,
      CodeDocumentType,
      AddPassenger,
      DeletePassenger,
      ...mapActions('RoomControl',['mLoadListRoomControl']),
  },
  computed: {
    ...mapState(["url_base"]),
    // ...mapState('RoomControl',['room_control_rc']),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    id_user: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.id_user;
    },

  },
};

//Ver habitacion
function ViewRoom() {
  this.isLoading = true;
  let me = this;
  let url = this.url_base + "room/view/" + this.id_room;

  axios({
    method: "GET",
    url: url,
    headers: {
      token: this.token,
      module: this.module,
      role: this.role,
    },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.room.id_room = response.data.result.id_room;
        me.room.id_type_room = response.data.result.id_type_room;
        me.room.room_number = response.data.result.room_number;
        me.room.photo = response.data.result.photo;
        me.room.beds = response.data.result.beds;
        me.room.maximum_guest = response.data.result.maximum_guest;
        me.room.price = response.data.result.price;
        me.room.description = response.data.result.description;
        me.room.type_room_name = response.data.result.type_room_name;

        me.reservation.id_reservation = response.data.reservation;
        me.btn_reserva = response.data.reservation == 0 ? true:false;

        me.room_control.id_room = response.data.result.id_room;
        me.room_control.id_type_room = response.data.result.id_type_room;
        me.room_control.id_user = me.id_user;
        me.room_control.price = response.data.result.price;
        me.room_control.quantity = 1;
        me.room_control.breakfasts = response.data.result.breakfasts;
        me.room_control.start_date = moment(new Date()).local().format("YYYY-MM-DD");
        me.room_control.final_date = moment(me.room_control.start_date, "YYYY-MM-DD").add('days',parseInt(1)).local().format("YYYY-MM-DD");
        me.room_control.date = moment(new Date()).local().format("YYYY-MM-DD");
        me.room_control.hour = moment(new Date()).local().format("HH:mm:ss");
        me.room_control.room_price = response.data.result.price;
        me.room_control.check_inn_price = (0).toFixed(2);
        me.room_control.check_out_price = (0).toFixed(2);

        

        me.isLoading = false;
    
      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
    })


}
//Calcular fecha final
function CalculateEndDate() {
  
  this.room_control.start_date = this.room_control.date;
  this.room_control.final_date = moment(this.room_control.start_date, "YYYY-MM-DD").add('days',parseInt(this.room_control.quantity)).local().format("YYYY-MM-DD");
  let room_price = parseFloat(this.room_control.quantity) * parseFloat(this.room_control.price); 
  this.room_control.room_price = room_price + parseFloat(this.room_control.check_inn_price) + parseFloat(this.room_control.check_out_price);


  this.room_control.price = parseFloat(this.room_control.price).toFixed(2);
  this.room_control.check_inn_price = parseFloat(this.room_control.check_inn_price).toFixed(2);
  this.room_control.check_out_price = parseFloat(this.room_control.check_out_price).toFixed(2);
  this.room_control.room_price = this.room_control.room_price.toFixed(2);

}
//modal new cliente
function ShowModalClient() {
  
  EventBus.$emit('ModalClient');
}

//agregar control habitacion
function AddRoomControl(_this) {

  let me = _this;

  me.isLoading = true;
  me.room_control.id_client = me.client.id;
  me.room_control.invoice_customer = me.invoice_customer.id;
  me.room_control.reservation = me.reservation;
  me.room_control.passengers = me.passengers;

  let url = me.url_base + "room-control/add";
  let data = me.room_control;
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: {
      "Content-Type": "application/json",
      token: me.token,
      module: me.module,
      role: me.role,
    },
  })
    .then(function (response) {
      if (response.data.status == 201) {
        me.isLoading = false;
        me.mLoadListRoomControl();
        Swal.fire("Sistema", "Se ha registrado la nueva estadia", "success");
        me.modal_new_room_control = false;
        me.room_control.id_client = '';
        me.room_control.observation = '';
        me.room_control.passengers = [];
        me.passengers = [];
        me.client = {id:1,full_name: 'CLIENTES VARIOS - 00000000'};
        me.invoice_customer = {id:1,full_name: 'CLIENTES VARIOS - 00000000'};
      } else if  (response.data.status == 409) {
        me.isLoading = false;
        me.mLoadListRoomControl();
        Swal.fire("Sistema", "La habitacion seleccionado ya no se encuentra disponible", "error");
        me.modal_new_room_control = false;
      }else{
        me.isLoading = false;
          Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    });
}

function Validate() {

  this.validate = false;

  this.errors.id_client = this.client == null || this.client == {} ? true : false;
  this.errors.invoice_customer = this.invoice_customer == null ? true : false;
  this.errors.start_date = this.room_control.start_date.length == 0 ? true : false;
  this.errors.final_date = this.room_control.final_date.length == 0 ? true : false;
  this.errors.price = this.room_control.price.length == 0 ? true : false;
  this.errors.quantity = this.room_control.quantity.length == 0 ? true : false;
  this.errors.room_price = this.room_control.room_price.length == 0 ? true : false;

  this.errors.breakfasts = this.room_control.breakfasts.length == 0 ? true : false;
  this.errors.reserve_medium = this.room_control.reserve_medium.length == 0 ? true : false;
  this.errors.transport = this.room_control.transport.length == 0 ? true : false;
  this.errors.reason_for_stay = this.room_control.reason_for_stay.length == 0 ? true : false;
  this.errors.way_to_pay = this.room_control.way_to_pay.length == 0 ? true : false;
  
  
 
  if (this.errors.id_client) { this.validate = true; return false;}else{ this.validate = false; }
  if (this.errors.invoice_customer) { this.validate = true; return false;}else{ this.validate = false; }
  if (this.errors.start_date) { this.validate = true; return false;}else{ this.validate = false; }
  if (this.errors.final_date) { this.validate = true; return false;}else{ this.validate = false; }
  if (this.errors.price) { this.validate = true; return false;}else{ this.validate = false; }
  if (this.errors.quantity) { this.validate = true; return false;}else{ this.validate = false; }
  if (this.errors.room_price) { this.validate = true; return false;}else{ this.validate = false; }

  if (this.errors.breakfasts) { this.validate = true; return false;}else{ this.validate = false; }
  if (this.errors.reserve_medium) { this.validate = true; return false;}else{ this.validate = false; }
  if (this.errors.transport) { this.validate = true; return false;}else{ this.validate = false; }
  if (this.errors.reason_for_stay) { this.validate = true; return false;}else{ this.validate = false; }
  if (this.errors.way_to_pay) { this.validate = true; return false;}else{ this.validate = false; }
  if (this.errors.way_to_pay) { this.validate = true; return false;}else{ this.validate = false; }

  let me = this;

  if (!this.validate) {
    Swal.fire({
      title: "Esta seguro de ocupar la habitación?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Estoy de acuerdo!",
    }).then((result) => {
      if (result.value) {
        this.AddRoomControl(me);
      }
    });

  }
  

}



function GetReserva() {

  let me = this;
  let url = this.url_base + "reservation/view/"+this.reservation.id_reservation;
  axios({
    method: "GET",
    url: url,
    headers: {token: this.token, module: this.module,role: this.role },
  }).then(function (response) {
      if (response.data.status == 200) {
          me.reservation.id_type_room = response.data.result.reservation.id_type_room;
          me.reservation.id_reservation = response.data.result.reservation.id_reservation;
          me.reservation.id_client = response.data.result.reservation.id_client;
          me.reservation.id_room = response.data.result.reservation.id_room;
          me.reservation.from = response.data.result.reservation.from;
          me.reservation.to = response.data.result.reservation.to;
          me.reservation.nights = response.data.result.reservation.nights;
          me.reservation.price = response.data.result.reservation.price;
          me.reservation.total = response.data.result.reservation.total;
          me.reservation.reserve_medium = response.data.result.reservation.reserve_medium;
          me.reservation.observation = response.data.result.reservation.observation;
          me.reservation.state = response.data.result.reservation.state;

          me.room_control.id_room = response.data.result.reservation.id_room;
          me.room_control.id_type_room = response.data.result.reservation.id_type_room;
          me.room_control.id_user = me.id_user;
          me.room_control.price = response.data.result.reservation.price;
          me.room_control.quantity = response.data.result.reservation.nights;
          me.room_control.start_date = moment(new Date()).local().format("YYYY-MM-DD");
          me.room_control.final_date = moment(me.room_control.start_date, "YYYY-MM-DD").add('days',parseInt(response.data.result.reservation.nights)).local().format("YYYY-MM-DD");
          me.room_control.date = moment(new Date()).local().format("YYYY-MM-DD");
          me.room_control.hour = moment(new Date()).local().format("HH:mm:ss");
          me.room_control.room_price = parseFloat(response.data.result.reservation.price) * parseFloat(response.data.result.reservation.nights);
          me.room_control.room_price = me.room_control.room_price.toFixed(2);
          me.room_control.check_inn_price = (0).toFixed(2);
          me.room_control.check_out_price = (0).toFixed(2);
          me.room_control.reserve_medium = response.data.result.reservation.reserve_medium;
          me.room_control.payment_type = response.data.result.reservation.payment_type;
          me.room_control.observation = response.data.result.reservation.observation;
          
          me.client = {id: response.data.result.reservation.id_client,full_name:response.data.result.reservation.name +' '+ response.data.result.reservation.document_number }
          me.invoice_customer = {id: response.data.result.reservation.id_client,full_name:response.data.result.reservation.name +' '+ response.data.result.reservation.document_number }
      }
  })
}


function SearchPassenger() {

  
  let me = this;
  if (me.passenger.document_type == 1) {
      if (me.passenger.document_number.length == 8) {
        me.errors.document_number = false;

        ApiQuery.SearchDni(me.passenger.document_number).then((data) => {
          if (data.status == 200) {
            me.passenger.name = data.razon_social;
          }else{
            me.passenger.name = '';
          }
            
        });

      }else{
        me.errors.document_number = true;
        me.error_document_number = 'El nro de ducumento debe contener 8 digitos';
      }
  }

  if (me.passenger.document_type == 6) {
      if (me.passenger.document_number.length == 11) {
        me.errors.document_number = false;
        
        ApiQuery.SearchRuc(me.passenger.document_number).then((data) => {
          if (data.status == 200) {
            me.passenger.name = data.razon_social;
          }else{
            me.passenger.name = '';
          }
            
        });

      }else{
        me.errors.document_number = true;
        me.error_document_number = 'El nro de ducumento debe contener 11 digitos';
      }
  }
}
function AddPassenger() {

  if (this.passenger.document_type == 1) {
    this.errors.document_number = this.passenger.document_number.length != 8 ? true : false;
    this.error_document_number = 'El nro de ducumento debe contener 8 digitos';
  }else if(this.passenger.document_type == 6){
    this.errors.document_number = this.passenger.document_number.length != 11 ? true : false;
    this.error_document_number = 'El nro de ducumento debe contener 11 digitos';
  }else if(this.passenger.document_type == 4){
    this.errors.document_number = this.passenger.document_number.length != 12 ? true : false;
    this.error_document_number = 'El nro de ducumento debe contener 12 digitos';
  }else if(this.passenger.document_type == 7){
    this.errors.document_number = this.passenger.document_number.length != 12 ? true : false;
    this.error_document_number = 'El nro de ducumento debe contener 12 digitos';
  }else{
    this.errors.document_number = this.passenger.document_number.length == 0 ? true : false;
    this.error_document_number = 'Ingrese un nro de documento';
  }
  
  this.errors.name_passenger = this.passenger.name.length == 0 ? true : false
     
  

  if (this.errors.document_number || this.errors.name_passenger) {
    return false;
  }
  this.passengers.push(
    {
      document_type:this.passenger.document_type,
      document_number:this.passenger.document_number,
      name:this.passenger.name,
      state:1,
    }
  )
  this.passenger.document_number ='';
  this.passenger.name = ''; 

}
function CodeDocumentType(code) {
  return CodeToName.NameDocumentType(code);
}

function DeletePassenger(index) {

  for (var i = 0; i < this.passengers.length; i++) {
    if (i == index) {
      this.passengers.splice(i, 1);
      break;
    }
  }
}

</script>