<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo Control de Habitaciones</strong>
          </CCardHeader>
          <CCardBody>
            <b-row>

              <b-col sm="6" md="2">
                <b-button class="form-control" @click="ShowModalRoomToExpire" >Hab. x Vencer</b-button>
              </b-col>
              <b-col sm="6" md="2">
                <b-button class="form-control" @click="ShowModalReservedRoom" >Reservas Futuras</b-button>
              </b-col>
              <b-col sm="12" md="5"></b-col>
              
              <b-col sm="12" md="1">
                <button class="btn btn-success form-control text-center" @click="ExportExcel" type="button"><i class="fas fa-file-excel"></i></button>
              </b-col>
              <b-col sm="6" md="2">
                <b-input-group>
                  <b-form-input
                    v-model="search"
                    class="form-control"
                    @keyup="ChangeSearch"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-button variant="primary" @click="mLoadListRoomControl">
                      <b-icon icon="search"></b-icon
                    ></b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-col>
            </b-row>

            <b-row class="mt-4">
              <b-col sm="6" md="4" lg="4"  xl="4" v-for="(item,index) in room_control_rc" :key="index">
         
                  
                  <div :class="BackgroundColor(item.state)+' '+'border w-100 p-2 text-center'">
                      <strong>{{State(item.state) +' - '+item.type_room_name +' ( '+ item.room_number + ' )'}}</strong>
                  </div>
                  <div :class="BackgroundColor(item.state)+' '+'border w-100 p-2 text-center'">
                    <div class="text-center" style="height: 40px;">
                    <span style="font-size:16px"> {{ item.client_name }}</span>
                    </div>
                    <div class="text-center" style="height: 30px;">
                      <small> {{ item.client_name != item.customer_name ? item.customer_name:''  }}</small>
                    </div>
                  </div>
          
                  <b-row cols="5" :class="'no-gutters mb-2 '+StateExpire(item.expire)">
                    <b-col class="text-center border p-0">
                      <strong>{{ item.beds }}</strong> <br>
                      <small>Cama(s)</small>
                    </b-col>
                    <b-col class="text-center border p-0">
                      <strong>{{ item.maximum_guest }}</strong> <br>
                      <small>Pax(s)</small>
                    </b-col>
                    <b-col class="text-center border p-0">
                      <strong>{{ item.breakfasts }}</strong> <br>
                      <small>Desayuno(s)</small>
                    </b-col>
                    <b-col class="text-center border p-0">
                      <strong>S/ {{ item.price }}</strong> <br>
                      <small>Precio</small>
                    </b-col>
                    <b-col class="text-center border p-0">
                      <div class="w-100 text-center">
                        <small>{{ item.start_date }}</small>
                      </div>
                      <div class="w-100 text-center border-top">
                        <small>{{ item.final_date }}</small>
                      </div>
                    </b-col>
                   
                  </b-row>

                  <div :class="BackgroundColor(item.state)">
                        
                        <div :class="VerifyReservation(item.reservation)"><span>RESERVADO</span></div>

                        <b-row  v-if="item.state == 0" class="no-gutters border">
                          <b-col sm="12" class="p-1">
                            <b-button class="form-control" variant="dark" title="Nuevo" @click="ShowModalNewRoomControl(item.id_room)" >
                              <img src="@/assets/icons/app/nuevo.png" class="img-fluid" alt="Nuevo">
                            </b-button>
                          </b-col>
                        </b-row>


                        <b-row  cols="6" v-if="item.state == 1" class="no-gutters border">
                          
                          <b-col   class="p-1">
                            <b-button class="form-control" title="Abonos" variant="dark" @click="ShowModalPaymentAccount(item.id_room_control)" >
                              <img src="@/assets/icons/app/pagar.png" class="img-fluid" alt="Abonos">
                            </b-button>
                          </b-col>
                          <b-col   class="p-1">
                            <b-button class="form-control" title="Emitir Comprobante" variant="dark" @click="ShowModalPayment(item.id_room_control)" >
                              <img src="@/assets/icons/app/comprobante.png" class="img-fluid" alt="Emitir Comprobante">
                            </b-button>
                          </b-col>
                          <b-col   class="p-1">
                            <b-button class="form-control" title="Ver Detalle" variant="dark" @click="ShowModalRoomControlDetail(item.id_room,item.id_room_control)" >
                              <img src="@/assets/icons/app/detalle.png" class="img-fluid" alt="Ver Detalle">
                            </b-button>
                          </b-col>
                           <b-col   class="p-1">
                            <b-button class="form-control" title="Desocupar" variant="dark" @click="ConfirmEmpty(item.id_room_control)" >
                              <img src="@/assets/icons/app/salir.png" class="img-fluid" alt="Desocupar">
                            </b-button>
                          </b-col>
                          <b-col   class="p-1">
                            <b-button class="form-control" title="Mantenimiento" variant="dark" @click="ShowModalHouseKeeping(item.id_room_control)" >
                              <img src="@/assets/icons/app/limpieza.png" class="img-fluid" alt="Mantenimiento">
                            </b-button>
                          </b-col>
                          <b-col  class="p-1">
                            <b-button class="form-control" title="Anular" variant="dark" @click="ConfirmCancel(item.id_room_control)" >
                              <img src="@/assets/icons/app/anular.png" class="img-fluid" alt="Anular">
                            </b-button>
                          </b-col>
                         
                        </b-row>

                        <b-row  v-if="item.state == 2 || item.state == 3" class="no-gutters border">
                          <b-col md="6"  class="p-1">
                            <b-button class="form-control" variant="dark" @click="ShowModalRoomControlDetail(item.id_room,item.id_room_control)" >
                              <img src="@/assets/icons/app/detalle.png" class="img-fluid" alt="Ver Detalle">
                            </b-button>
                          </b-col>
                          <b-col  md="2"  class="p-1">
                            <b-button class="form-control" title="Mantenimiento" variant="dark" @click="ShowModalHouseKeeping(item.id_room_control)" >
                              <img src="@/assets/icons/app/limpieza.png" class="img-fluid" alt="Mantenimiento">
                            </b-button>
                          </b-col>
                          <b-col  md="4"  class="p-1">
                            <b-button class="form-control" title="Liberar Habitación" variant="dark" @click="ConfirmBreakFree(item.id_room_control)" >
                              <img src="@/assets/icons/app/salir.png" class="img-fluid" alt="Liberar Habitación">
                            </b-button>
                          </b-col>
                        </b-row>

                  </div>

                  <b-row cols="5"  :class="'no-gutters mb-2 '+StateExpire(item.expire)">
                    <b-col  class="text-center border p-1">
                      <button :disabled="item.state != 1" type="button" title="Habitación" @click="ShowModalRoom(item.id_room,item.id_room_control)" class="btn bg-info-button-room form-control text-center">
                           <img src="@/assets/icons/app/habitacion.png" class="img-fluid" alt="">
                      </button>
                    </b-col>
                    <b-col  class="text-center border p-1">
                      <button :disabled="item.state != 1" type="button" title="Frigobar" @click="ShowModalMinibar(item.id_room_control)" class="btn bg-info-button-room form-control text-center">
                        <img src="@/assets/icons/app/frigobar.png" class="img-fluid" alt="">
                      </button>
                    </b-col>
                    <b-col  class="text-center border p-1">
                      <button :disabled="item.state != 1" type="button" title="Consumo" @click="ShowModalConsumer(item.id_room_control)" class="btn bg-info-button-room form-control text-center">
                        <img src="@/assets/icons/app/consumo.png" class="img-fluid" alt="">
                      </button>
                    </b-col>
                    <b-col  class="text-center border pr-1">
                      <div class="w-100 text-right">
                        <small>Total</small>
                      </div>
                      <div class="w-100 text-right border-top">
                        <small>Deuda</small>
                      </div>
                    </b-col>
                    <b-col  class="text-center border pr-1">
                      <div class="w-100 text-right">
                        <small>S/ {{ item.total }}</small>
                      </div>
                      <div class="w-100 text-right border-top">
                        <small>S/ {{ item.balance }}</small>
                      </div>
                    </b-col>
                  </b-row>
                   

              </b-col>
            </b-row>



          </CCardBody>
        </CCard>
      </CCol>
    </CRow>



    <ModalMinibar/>
    <ModalConsumer/>
    <ModalRoom/>
    <ModalNewRoomControl/>
    <ModalPayment/>
    <ModalPaymentAccount/>
    <ModalRoomControlDetail/>
    <ModalRoomToExpire/>
    <ModalReservedRoom/>
    <ModalHousekeeping/>
  </div>
</template>
<style scoped>
  td{
    vertical-align: middle;
  }
  .bg-free{ background-color: #FADED2; color: #000;}
  .bg-occupied{ background-color: #A0CBEE; color: #000;}
  .bg-waiting-for-maintenance{ background-color: #FE1859; color: #fff;}
  .bg-maintenance{ background-color: #FE1859; color: #fff;}
  .bg-info-room{ background-color: #455a64; color: #fff;}
  .bg-info-expire{ background-color: #F27280; color: #000;}

  .bg-info-button-room{ background-color: #536DFE; color: #fff;}


  .box {
    width: 200px; height: 300px;
    position: relative;
    border: 1px solid #BBB;
    background: #EEE;
  }
  .ribbon {
    position: absolute;
    right: 12px; top: -5px;
    z-index: 1;
    overflow: hidden;
    width: 75px; height: 75px;
    text-align: right;
  }
  .ribbon span {
    font-size: 10px;
    font-weight: bold;
    color: #FFF;
    text-transform: uppercase;
    text-align: center;
    line-height: 20px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    width: 100px;
    display: block;
    background: #71CC49;
    background: linear-gradient(#9BC90D 0%, #71CC49 100%);
    box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
    position: absolute;
    top: 19px; right: -21px;
  }
  .ribbon span::before {
    content: "";
    position: absolute; left: 0px; top: 100%;
    z-index: -1;
    border-left: 3px solid #71CC49;
    border-right: 3px solid transparent;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #71CC49;
  }
  .ribbon span::after {
    content: "";
    position: absolute; right: 0px; top: 100%;
    z-index: -1;
    border-left: 3px solid transparent;
    border-right: 3px solid #71CC49;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #71CC49;
  }
  
</style>>
<script>
const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
var moment = require("moment");

import { mapState,mapMutations,mapActions } from "vuex";
import EventBus from "@/assets/js/EventBus";

// MODULES
import ModalMinibar from './components/ModalMinibar'
import ModalConsumer from './components/ModalConsumer'
import ModalRoom from './components/ModalRoom'
import ModalNewRoomControl from './components/ModalNewRoomControl'
import ModalPayment from './components/ModalPayment'
import ModalPaymentAccount from './components/ModalPaymentAccount'
import ModalRoomControlDetail from './components/ModalRoomControlDetail'
import ModalRoomToExpire from './components/ModalRoomToExpire'
import ModalReservedRoom from './components/ModalReservedRoom'
import ModalHousekeeping from './components/ModalHousekeeping'


export default {
  name: "ControlHabitacionList",
  components:{
      ModalMinibar,
      ModalConsumer,
      ModalRoom,
      ModalNewRoomControl,
      ModalPayment,
      ModalPaymentAccount,
      ModalRoomControlDetail,
      ModalRoomToExpire,
      ModalReservedRoom,
      ModalHousekeeping,
  },
  data() {
    return {
      module:'RoomControl',
      search:'',
    };
  },
  mounted() {
    this.mLoadListRoomControl();
   
  },
  methods: {
    ChangeSearch,
    ShowModalMinibar,
    ShowModalConsumer,
    ShowModalRoom,
    ShowModalNewRoomControl,
    ShowModalPayment,
    ShowModalPaymentAccount,
    ShowModalRoomControlDetail,
    ShowModalRoomToExpire,
    ShowModalReservedRoom,
    ShowModalHouseKeeping,

    ConfirmCancel,
    Cancel,
    ConfirmEmpty,
    Empty,
    ConfirmBreakFree,
    BreakFree,

    ExportExcel,
    
    BackgroundColor,
    State,
    StateExpire,

    VerifyReservation,

    ...mapActions('RoomControl',['mLoadListRoomControl']),
    ...mapActions('RoomControl',['mShowModalMinibar']),
    ...mapMutations('RoomControl',['mListRoomControl','mSetSearch'])
  },

  computed: {
    ...mapState(["url_base"]),
    ...mapState('RoomControl',['room_control_rc']),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
  },
};

function ChangeSearch() {
  this.mSetSearch(this.search);
}
function State(state) {
  let TextState = 'LIBRE';
   switch (state) {
    case 1: TextState = 'OCUPADO'; break;
    case 2: TextState = 'A MANTENIMIENTO'; break;
    case 3: TextState = 'EN MANTENIMIENTO'; break;
    default: break;
  }
  return TextState;
}

function StateExpire(state) {
  if (state == 0) {
    return 'bg-info-room text-white';
  } else {
    return 'bg-info-expire text-white';
  }
}

function BackgroundColor(state) {
    // 0 libre 
    // 1 ocupado 
    // 2 libre para mantenimiento 
    // 3 en mantenimiento 
    // 4 finalizado 
    // 5 reservado 
    // 6 anulado 
    // 9 eliminado 
  let BackgroundColor = 'bg-free';
  switch (state) {
    case 1: BackgroundColor = 'bg-occupied'; break;
    case 2: BackgroundColor = 'bg-waiting-for-maintenance'; break;
    case 3: BackgroundColor = 'bg-maintenance'; break;
    // case 4: BackgroundColor = 'bg-warning'; break;
    // case 5: BackgroundColor = 'bg-warning'; break;
    // case 6: BackgroundColor = 'bg-warning'; break;
    // case 7: BackgroundColor = 'bg-info'; break;
    default: break;
  }

  return BackgroundColor;
  
}
// abrir modal frigobar
function ShowModalMinibar(id_room_control) {
  EventBus.$emit('ModalMinibarShow',id_room_control);
}
// abrir modal consumer
function ShowModalConsumer(id_room_control) {
  EventBus.$emit('ModalConsumerShow',id_room_control);
}
// abrir modal consumer
function ShowModalRoom(id_room,id_room_control) {
  // let data = { id_room:id_room,id_room_control:id_room_control };
  EventBus.$emit('ModalRoomShow',id_room,id_room_control);
}
// abrir modal nuevo control habitacion
function ShowModalNewRoomControl(id_room) {
  EventBus.$emit('ModalNewRoomControlShow',id_room);
}
// abrir modal nuevo control habitacion
function ShowModalPayment(id_room_control) {
  EventBus.$emit('ModalPaymentShow',id_room_control);
}
function ShowModalPaymentAccount(id_room_control) {
  EventBus.$emit('ModalPaymentAccountShow',id_room_control);
}


function ShowModalRoomControlDetail(id_room,id_room_control) {
  EventBus.$emit('ModalRoomControlDetailShow',id_room,id_room_control);
}

function ShowModalRoomToExpire() {
  EventBus.$emit('ModalRoomToExpire');
}

function ShowModalReservedRoom() {
  EventBus.$emit('ModalReservedRoom');
}

function ShowModalHouseKeeping(id_room_control) {
  EventBus.$emit('ModalHousekeepingShow',id_room_control);
}

function ConfirmCancel(id_room_control) {
    let me = this;
    Swal.fire({
    title: 'Esta seguro de Anular la estadia de la habitacion?',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Si, Estoy de Acuerdo!'
  }).then((result) => {
    if (result.isConfirmed) {
      me.Cancel(me,id_room_control);
    }
  })
}


function Cancel(_this,id_room_control) {

  let me = _this;
  let url = _this.url_base + "room-control/cancel/" + id_room_control;

  axios({
    method: "GET",
    url: url,
    headers: {
      token: _this.token,
      module: _this.module,
      role: 4,
    },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        Swal.fire({ icon: 'success', text: 'Se anulo la habitación', timer: 2000,})
          me.mLoadListRoomControl();
      }else if (response.data.status == 400){
        Swal.fire({ icon: 'error', text: 'No se puede anular la habitación seleccionada', timer: 2000,})
      } else {
        Swal.fire({ icon: 'error', text: 'A ocurido un error', timer: 2000,})
      }
    })

}


function ConfirmEmpty(id_room_control) {
    let me = this;
    Swal.fire({
    title: 'Esta seguro de pasar a mantenimiento la habitación?',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Si, Estoy de Acuerdo!'
  }).then((result) => {
    if (result.isConfirmed) {
      me.Empty(me,id_room_control);
    }
  })
}

function Empty(_this,id_room_control) {
  let me = _this;
  let url = me.url_base + "room-control/maintenance/" + id_room_control;
  axios({
    method: "GET",
    url: url,
    headers: {
      token: me.token,
      module: me.module,
      role: 3,
    },
  })
    .then(function (response) {
      if (response.data.status == 200) {
          Swal.fire({ icon: 'success', text: response.data.message, timer: 3000,})
            me.mLoadListRoomControl();
      } else {
          Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
    })
}

function ConfirmBreakFree(id_room_control) {
    let me = this;
    Swal.fire({
    title: 'Esta seguro de liberar la habitación?',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Si, Estoy de Acuerdo!'
  }).then((result) => {
    if (result.isConfirmed) {
      me.BreakFree(me,id_room_control);
    }
  })
}


function BreakFree(_this,id_room_control) {

  let me = _this;
  let url = me.url_base + "room-control/breakfree/" + id_room_control;

  axios({
    method: "GET",
    url: url,
    headers: {
      token: me.token,
      module: me.module,
      role: 3,
    },
  })
    .then(function (response) {
      if (response.data.status == 200) {
            me.mLoadListRoomControl();
      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
    })
}

function VerifyReservation(reservation) {

  if (reservation == 0) {
    return 'd-none';
  }else{
    return 'ribbon';
  }
  
  
    

}

function ExportExcel() {
  let me = this;
  let url = this.url_base + "export-room-control";
  
  window.open(url,'_blank');
}


// permisos
function Permission(module_permission) {
  let user_permissions = window.localStorage.getItem("user_permissions");
  user_permissions = JSON.parse(JSON.parse(je.decrypt(user_permissions)));
  if (user_permissions.indexOf(module_permission) > -1) {
    return true;
  } else {
    return false;
  }
}
</script>
